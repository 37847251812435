.tooltipComponent {
  position: relative;
  display: inline-block;
  margin-left: 8px;
  vertical-align: top; }
  .tooltipComponent:hover .tooltipText {
    visibility: visible; }
  .tooltipComponent .tooltipText {
    visibility: hidden;
    width: max-content;
    max-width: 225px;
    background-color: #3042D4;
    border-radius: 16px;
    padding: 20px 20px;
    position: absolute;
    z-index: 1;
    font-family: 'Poppins', sans-serif !important;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 22px;
    color: white; }
    .tooltipComponent .tooltipText::after {
      content: "";
      position: absolute;
      right: 100%;
      border-width: 6px;
      border-style: solid;
      border-color: transparent #3042D4 transparent transparent; }
    .tooltipComponent .tooltipText.horizontal {
      top: -24px;
      left: 24px; }
      .tooltipComponent .tooltipText.horizontal::after {
        top: 30px; }
    .tooltipComponent .tooltipText.vertical {
      top: -100px;
      left: -166px; }
      .tooltipComponent .tooltipText.vertical::after {
        top: 92px;
        left: 165px;
        transform: rotate(-90deg); }
  @media screen and (max-width: 767px) {
    .tooltipComponent {
      cursor: pointer; }
      .tooltipComponent .tooltipText {
        display: none; } }
