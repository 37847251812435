@import 'src/styles/_defines';

.profileMockup {
  padding-top: 60px;

  .profileHead {
    @media (max-width: 1920px) {
      padding: 0 360px;
    }
    @media (max-width: 1600px) {
      padding: 0 200px;
    }
    @media (max-width: 1400px) {
      padding: 0 150px;
    }
    @media (max-width: 1024px) {
      padding: 0 80px;
    }
    height: 288px;
    background-color: #42bd98;
    display: flex;
    align-items: flex-end;

    .content {
      border-radius: 10px;
      background-color: white;
      width: 100%;
      margin-bottom: -48px;
      padding: 64px 0;

      display: flex;
      align-items: center;
      justify-content: space-between;

      .info {
        display: flex;
        margin-left: 132px;

        .dropzone {
          width: 125px !important;
          height: 125px !important;
          margin-right: 52px;
        }

        > img {
          width: 125px;
          height: 125px;
          border-radius: 50%;
          margin-right: 52px;
          object-fit: cover;
          border: 1px solid $blue_solid_light;
        }

        .name {
          font-family: 'Poppins' !important;
          font-style: normal;
          font-weight: 600;
          font-size: 36px;
          line-height: 54px;
          letter-spacing: -0.01em;
          color: #3042d4;
          margin: 0 0 10px 0;
        }

        .desc {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 400;
          font-size: 17px;
          line-height: 1.05;
          margin: 0 0 18px 0;
        }

        .ratings {
          display: flex;
          align-items: center;
          gap: 28px;

          > div {
            display: flex;
            align-items: center;

            > img {
              margin-right: 6px;
            }

            > p {
              font-family: 'Poppins';
              font-style: normal;
              font-weight: 500;
              font-size: 17px;
              line-height: 116%;
              color: #212b35;
              margin: 0;
            }
          }
        }
      }

      .buttons {
        margin-right: 46px;
        display: flex;
        gap: 16px;
      }
    }
  }

  .charityRole {
    display: flex;
    align-items: center;
    padding: 24px 0;
    border-bottom: 1px solid $blue_light;
  }

  .contentContainer {
    display: flex;
    gap: 35px;
    background-color: #eeeeee;
    
    @media (max-width: 1920px) {
      padding: 24px 360px 80px 360px;
    }
    @media (max-width: 1600px) {
      padding: 24px 200px 80px 200px;
    }
    @media (max-width: 1400px) {
      padding: 24px 150px 80px 150px;
    }
    @media (max-width: 1024px) {
      padding: 24px 80px 80px 80px;
    }

    .experienceSection {
      width: 65%;
      margin-top: 80px;

      .bioContainer {
        background-color: white;
        border: 1px solid $blue_light;
        padding: 40px;
        border-radius: 16px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        margin-bottom: 36px;

        .bio {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 174%;
          margin: 0;
          margin-bottom: 16px;
          padding-left: 18px;
        }
      }

      .experienceContainer {
        background-color: white;
        border: 1px solid $blue_light;
        padding: 40px;
        border-radius: 16px;
        display: flex;
        flex-direction: column;
        margin-bottom: 36px;

        .exp {
          .networkLabel {
            display: flex;
            align-items: center;
            padding: 4px 6px;
            background: #f8f8f8;
            margin-bottom: 30px;

            > img {
              width: 28px;
              height: 28px;
              border-radius: 50%;
              margin-right: 10px;
            }

            > p {
              font-family: 'Poppins';
              font-style: normal;
              font-weight: 700;
              font-size: 16px;
              line-height: 116%;
              color: #212b35;
              margin: 0;
            }
          }

          .task {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 22px;

            p {
              font-family: 'Poppins';
              font-style: normal;
              font-weight: 400;
              font-size: 17px;
              line-height: 30px;
              color: #000000;
              margin: 0;

              &:last-child {
                font-weight: 500;
                line-height: 174%;
                position: relative;
                width: 44px;
                text-align: right;

                &::before {
                  content: '';
                  position: absolute;
                  width: 1px;
                  height: 25px;
                  background-color: #e4eaf7;
                  left: -15px;
                  top: 2px;
                }
              }
            }
          }

          .h-line {
            width: 100%;
            height: 1px;
            background-color: #e4eaf7;
            margin-bottom: 30px;
          }

          .review {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 17px;
            line-height: 30px;
            color: #637381;
            margin: 0 0 14px 0;
          }

          .dateAndStar {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 30px;

            .star {
              display: flex;
              align-items: center;
              border: 1px solid #e6e8ec;
              border-radius: 4px;
              padding: 4px 8px;

              > img {
                margin-right: 6px;
              }

              > p {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 500;
                font-size: 17px;
                line-height: 116%;
                color: #212b35;
                margin: 0;
              }
            }

            .date {
              font-family: 'Poppins';
              font-style: normal;
              font-weight: 400;
              font-size: 17px;
              line-height: 30px;
              text-align: right;
              color: #6e7581;
              margin: 0;
            }
          }
        }
      }
    }
  }

  .skillSection {
    flex: 1;
    width: 30%;
    margin-top: 80px;

    .skillsContainer {
      background-color: white;
      border: 1px solid $blue_light;
      padding: 40px;
      border-radius: 16px;
      display: flex;
      flex-direction: column;
      margin-bottom: 36px;
    }

    .charityRoleContainer {
      background-color: white;
      border: 1px solid $blue_light;
      padding: 24px 32px;
      display: flex;
      align-items: center;
      flex-direction: column;
      border-radius: 16px;
      margin-bottom: 24px;

      .charityDescription {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* number of lines to show */
        line-clamp: 2;
        -webkit-box-orient: vertical;
        margin: 0;
        line-height: 20px;
      }

      .charityLogo {
        height: 80px;
        width: 80px;
      }
    }

    > div {
      h2 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 116%;
        color: #212b35;
      }
    }

    .networksContainer {
      background-color: white;
      border: 1px solid $blue_light;
      padding: 40px;
      border-radius: 16px;
      display: flex;
      flex-direction: column;
      margin-bottom: 36px;

      .flex-container {
        margin: -16px;
      }

      .charityContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 33%;
        text-decoration: none;
        margin: 0;
        padding: 16px;

        .charityLogo {
          width: 80px;
          height: 80px;
          border-radius: 50%;
          object-fit: cover;
          border: 1px solid $blue_solid_light;
        }

        .charityTitle {
          text-align: center;
          white-space: nowrap;
          text-overflow: ellipsis;
          max-width: 88px;
          overflow: hidden;
          color: $secondary_body;
          font-weight: normal;
          margin-top: 8px;
          margin-bottom: 0;
        }
      }

      .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 30px;

        a {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 116%;
          color: #3042d4;
          margin: 0;
          text-decoration: none;
        }
      }
    }

    .causesContainer {
      background-color: white;
      border: 1px solid $blue_light;
      padding: 40px;
      border-radius: 16px;
      display: flex;
      flex-direction: column;
      margin-bottom: 36px;

      h2 {
        margin-bottom: 16px;
      }
    }
  }

  .charityLogo {
    height: 28px;
    width: 28px;
    border-radius: 50%;
    border: 1px solid $blue_solid_light;
  }

  .emptyContainer {
    background-color: white;
    border: 1px solid $blue_light;
    padding: 40px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 36px;
  }

  .emptySubtitle {
    font-family: Poppins;
    font-size: 17px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: center;
  }

  .emptyDescription {
    font-family: Poppins;
    font-size: 15px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: center;
    color: #828282;
  }
}