@import 'src/styles/_defines';

.sidebarSection {
  display: flex;
  flex-direction: column;
  .header {
    display: flex;
    align-items: center;
    padding: 0px 17px;
    h2 {
      font-size: 14px;
      font-weight: 700;
      margin: 0;
      padding: 10px 5px;
  
      img {
        padding-right: 9px;
      }
    }
  }
  .body {
    display: flex;
    flex-direction: column;
    .pinedSection {
      .item {
        margin-bottom: 10px;
      }
    }
    .item {
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      height: 40px;
      padding-left: 46px;
      &:hover {
        .menu {
          visibility: visible;
        }
      }
      &.selected {
        background-color: #c1c7ff;
      }
      .infoView {
        display: flex;
        align-items: center;
        .profilePic {
          display: flex;
          margin-right: 7px;
          img {
            width: 28px;
            height: 28px;
            border-radius: 50%;
            object-fit: contain;
          }
          &.multi {
            img {
              width: 21px;
              height: 21px;
            }
            span {
              width: 22px;
              height: 22px;
              margin-top: 4px;
              margin-left: -14px;
              border-radius: 50%;
              background-color: #3042D4;
              color: white;
              text-align: center;
            }
          }
        }
        a {
          text-decoration: none;
          color: $primary_body;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 140px;
          margin-right: 6px;
        }
        .unreadMessageBadge {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 21px;
          height: 21px;
          background-color: #E5153F;
          color: white;
          border-radius: 50%;
        }
      }
      .menu {
        visibility: hidden;
        &.pined {
          visibility: visible;
        }
        button {
          transition: 0s !important;
        }
      }
    }
  }
  .newGroupChat {
    display: flex;
    align-items: center;
    padding-left: 46px;
    cursor: pointer;
    img {
      margin-right: 7px;
    }
  }
}