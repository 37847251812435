@import 'src/styles/_defines';

.leaveFeedbackContainer {
  .headerBackground {
    height: 96px;
    background-color: #3042d4;
    z-index: 0;
    margin: -40px;
    padding: 40px;
    margin-bottom: 0;
  }

  .modalPaper {
    width: 1000px;
  }

  .modalClose {
    display: none !important;
  }

  .row {
    padding-top: 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid $grey_light;
    margin-left: -40px;
    margin-right: -40px;
  }

  .starsContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0 -4px;
    margin-bottom: 32px;

    .iconButton {
      padding: 0 !important;
    }
  }

  .starsHeaderContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0 -4px;

    .iconButton {
      padding: 0 !important;
    }
  }
}
