// Colors
$white: #ffffff;
$black_medium: #333333;
$black: #000000;

$grey_light: #bdbdbd;
$grey_lighter: #ebebeb;
$grey_dark: #4f4f4f;
$grey: #828282;
$grey_bg: #f1f3f6;
$grey_bg_2: #f3f5f7;
$grey_border: #e3e5e5;
$grey_900: #232933;

$blue_light: #e4eaf7;
$blue_solid_light: #b5bdc4;

$green_dark: #33a676;
$green_light: #bdded6;

// Primary color
$primary_color: #3042d4;
$primary_color_light: #5A69DD;

// Secondary Accent
$secondary_color: #20c1c9;

// Tertiary Accent
$tertiary_color: #f53920;

// Primary Body
$primary_body: #212b35;

// Secondary body
$secondary_body: #637381;

// Tertiary Body
$tertiary_body: #b5bdc4;

// Light bg
$light_bg: #faf7f5;

// Porcelain
$procelain: #f1efeb;

// Dark Blue 800
$blue_dark: #1a3465;

$screen-sm-min: 768px;
$screen-md-min: 992px;
$screen-lg-min: 1170px;

$screen-xs-max: $screen-sm-min - 1;
$screen-sm-max: $screen-md-min - 1;
$screen-md-max: $screen-lg-min - 1;
