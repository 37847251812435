.finishContainer {
  text-align: center; }
  .finishContainer .finishContentContainer {
    width: 500px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
    .finishContainer .finishContentContainer .eventPic {
      width: 100%;
      height: 285px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover; }
    .finishContainer .finishContentContainer .eventIconContainer {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      background-color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      margin-top: 20px; }
      .finishContainer .finishContentContainer .eventIconContainer .eventIcon {
        width: 28px !important;
        height: 28px !important; }
    .finishContainer .finishContentContainer .eventContent {
      margin: 50px 0px; }
  @media screen and (max-width: 767px) {
    .finishContainer .finishContentContainer {
      width: 100%; } }
