.topBarContainer {
  background: #212b35;
  top: 0px;
  width: 100%;
  text-align: center;
  min-height: 60px; }
  .topBarContainer a {
    font-weight: 700; }
  .topBarContainer p {
    display: inline-block;
    color: #AAB5BD; }
