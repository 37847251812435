.dialogComponent .defaultDialog {
  max-width: 600px !important; }

.dialogComponent .closeIcon {
  position: absolute;
  right: 25px;
  top: 25px;
  cursor: pointer; }

.dialogComponent .buttonsContainer {
  text-align: right;
  margin-top: 40px; }
  .dialogComponent .buttonsContainer .btnCancel {
    border: 1px solid red;
    margin-right: 10px; }
    .dialogComponent .buttonsContainer .btnCancel .borderedButtonContent svg {
      color: red !important; }
    .dialogComponent .buttonsContainer .btnCancel p {
      color: red; }
      .dialogComponent .buttonsContainer .btnCancel p:hover {
        color: red; }
