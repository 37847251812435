.notificationsPopover {
  width: 380px !important;
  padding-top: 24px;
  padding-bottom: 24px;
  border-radius: 16px !important;

  > div {
    overflow-y: hidden !important;
  }

  .notificationsEmpty {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 400px;
    justify-content: center;
  }

  .notificationsGroup {
    overflow-y: scroll;
    height: 340px;

    .loadmore {
      text-align: center;
      font-family: Poppins;
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;

      &:hover {
        color: #3042D4;
      }
    }

    .notificationsContainer {
      border-bottom: 1px solid #EEEEEE;
      padding: 15px 12px;

      &:hover {
        background-color: #EEEEEE;
        cursor: pointer;

        .action {
          color: #3042D4;
        }
      }

      .time {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        text-align: right;
        color: #637381;
      }

      .notifications {
        display: flex;
        justify-content: start;
        align-items: center;

        .description {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 15px;
        }

        .detail {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 15px;
          color: #637381;
        }

        .action {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 15px;
        }
      }

      .charityPicture {
        width: 46px;
        height: 46px;
        border-radius: 25px;
        border: 1px solid grey;
      }
    }
  }
  .modalCover {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1000;
  }

  .skeleton-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 15px;

    .skeleton-description {
      display: flex;
      flex-direction: column;
    }

    .skeleton-time {
      display: flex;
      justify-content: flex-end;
    }
  }
}