.updateSkillsModal {

  .modalPaper {
    width: 640px;
  }
  
  .select {
    border: 1px solid #e3e5e6;
    border-radius: 8px;
    color: #b5bdc4;
    font-family: 'Poppins' !important;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    padding: 14px 16px;
    appearance: none;
    background: transparent;
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='11' viewBox='0 0 20 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L10 10L19 1' stroke='%23B5BDC4' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 16px center;
    outline: 0;
    cursor: pointer;
    width: 100%;

    &.active {
      font-weight: 600;
    }
  }
}