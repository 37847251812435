@import 'src/styles/_defines';

.defaultModal {
  padding: 20px;
  max-width: 600px !important;
  h3 {
    margin-top: 0px;
    color: $primary_color;
  }

  .specificContainer {
    display: block;
    margin-bottom: 20px;
    .inputContainer {
      display: inline-block;
      width: 90%;
    }
    .addItem {
      display: inline-block;
      fill: $grey_light;
      opacity: 0;
      &.enabled {
        opacity: 1;
      }
    }
  }
}

.addBioContainer > div {
  border-radius: 20px !important;

  > div {
    padding: 50px 40px 40px 40px !important;
    position: relative;

    .closeIcon {
      position: absolute;
      right: 20px;
      top: 20px;
      cursor: pointer;
    }

    .title {
      font-style: normal;
      font-weight: 600;
      font-size: 21px;
      line-height: 116%;
      color: #000000;
    }

    textarea:focus-visible {
      outline-color: #e3e5e6e1;
    }

    .bioEditor {
      width: 100%;
      height: 223px;
      border: 1px solid #e3e5e6;
      padding: 22px;
      border-radius: 8px;
      margin-bottom: 26px;
    }

    #loading-overlay {
      border-radius: 20px;
    }
  }
}

.AddCausesContainer > div {
  border-radius: 20px !important;
  display: flex;
  justify-content: center;

  > div {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }

  .title {
    font-family: 'Petrona';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 22px;
    text-align: center;
    color: #000;
  }

  .subTitle {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 22px;
    color: #000;
  }

  .isClickable {
    border-color: #637381 !important;

    span {
      color: #637381 !important;
    }
  }

  .selected > span > span {
    color: white !important;
  }

  #loading-overlay {
    border-radius: 20px;
  }
}
