.profileCharitiesContainer h1 {
  color: #3042d4; }

.profileCharitiesContainer .profileHeader {
  height: 320px;
  background-color: #faf7f5;
  padding: 100px 0px 100px 0px; }
  .profileCharitiesContainer .profileHeader .imgUser {
    width: 140px;
    height: 140px;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover; }
  .profileCharitiesContainer .profileHeader .headerInformationContainer {
    height: 140px; }
    .profileCharitiesContainer .profileHeader .headerInformationContainer .locationContainer {
      margin: 0px;
      color: #212b35; }
  .profileCharitiesContainer .profileHeader .statsContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center; }
    .profileCharitiesContainer .profileHeader .statsContainer .stat {
      text-align: center;
      margin-right: 5px;
      background-color: #ffffff;
      padding: 20px 0px 10px 0px;
      width: 75px; }
      .profileCharitiesContainer .profileHeader .statsContainer .stat h3 {
        margin-bottom: 5px;
        margin-top: 15px;
        color: #20c1c9; }
      .profileCharitiesContainer .profileHeader .statsContainer .stat h5 {
        margin: 0px;
        text-transform: uppercase;
        color: #20c1c9; }

.profileCharitiesContainer .profileCharitiesContent h2 {
  font-weight: 700; }

.profileCharitiesContainer .profileCharitiesContent .content {
  margin-top: 50px;
  margin-bottom: 10px; }
  .profileCharitiesContainer .profileCharitiesContent .content .charity {
    display: flex;
    align-items: center;
    padding: 40px 0px 40px 0px;
    border-bottom: 1px solid #e4eaf7;
    text-decoration: none; }
    .profileCharitiesContainer .profileCharitiesContent .content .charity .pic {
      width: 85px;
      height: 85px;
      border-radius: 50%;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover; }
    .profileCharitiesContainer .profileCharitiesContent .content .charity .nameContainer {
      margin-left: 15px; }
      .profileCharitiesContainer .profileCharitiesContent .content .charity .nameContainer a {
        text-decoration: none;
        color: #212b35; }
    .profileCharitiesContainer .profileCharitiesContent .content .charity .buttonsContainer {
      display: flex; }
      .profileCharitiesContainer .profileCharitiesContent .content .charity .buttonsContainer .viewEventsButton {
        margin-left: 15px; }

@media screen and (max-width: 1169px) {
  .profileCharitiesContainer .profileHeader {
    padding: 70px 40px; }
  .profileCharitiesContainer .contentAboutProfileContainer {
    padding: 0px 15px; } }

@media screen and (max-width: 991px) {
  .profileCharitiesContainer .profileHeader .headerInformationContainer {
    padding-left: 40px; }
  .profileCharitiesContainer .profileHeader .profilePicture .dropzone {
    width: 120px !important;
    height: 120px !important; }
  .profileCharitiesContainer .profileHeader .statsContainer {
    justify-content: end; } }

@media screen and (max-width: 767px) {
  .profileCharitiesContainer .profileHeader {
    padding: 40px;
    height: auto; }
    .profileCharitiesContainer .profileHeader .headerInformationContainer {
      padding-left: 25px; }
      .profileCharitiesContainer .profileHeader .headerInformationContainer .locationContainerInterior p {
        font-size: 12px; }
    .profileCharitiesContainer .profileHeader .imgUser {
      width: 115px;
      height: 115px; }
    .profileCharitiesContainer .profileHeader .statsContainer {
      margin-top: 40px; }
  .profileCharitiesContainer .leftContainer .aboutContainer {
    margin: 0px; }
  .profileCharitiesContainer .leftContainer .pastExperiencesContainer {
    margin-top: 40px;
    margin-right: 0px; }
  .profileCharitiesContainer .skillsContainer {
    margin-top: 30px; }
  .profileCharitiesContainer .rightContainer {
    padding: 15px; }
  .profileCharitiesContainer .profileCharitiesContent .content {
    padding: 20px; }
    .profileCharitiesContainer .profileCharitiesContent .content .charity {
      display: block; }
      .profileCharitiesContainer .profileCharitiesContent .content .charity .buttonsContainer {
        display: block;
        margin-top: 20px; }
        .profileCharitiesContainer .profileCharitiesContent .content .charity .buttonsContainer .viewEventsButton {
          margin-left: 0px;
          margin-top: 10px; }
        .profileCharitiesContainer .profileCharitiesContent .content .charity .buttonsContainer .btn_primary {
          width: 100%; } }
