@import 'src/styles/_defines';

.charityList {
  .charity {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .charityInner {
      display: flex;

      .charityLogo {
        width: 84px;
        height: 84px;
        margin-right: 18px;
        border-radius: 50%;
        object-fit: cover;
        border: 1px solid #E3E5E5;
      }

      .details {
        a {
          color: $black;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }

        .charityName {
          font-weight: 600;
          font-size: 18px;
          line-height: 24px;
          margin: 0 0 12px 0;
        }

        .causesList {
          display: flex;
          align-items: center;
          margin-bottom: 16px;

          p {
            font-weight: 400;
            font-size: 15px;
            line-height: 17px;
            color: #6e7581;
            margin: 0;
          }

          .v-line {
            width: 1px;
            height: 22px;
            background-color: #ebeff9;
            margin: 0 8px;
          }
        }

        .members {
          display: flex;
          align-items: center;

          .location {
            border-right: 1px solid #EBEFF9;

            margin-right: 12px;
            padding-right: 12px;
          }

          img {
            margin-right: 6px;
          }

          p {
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: #637381;
            margin: 0;
          }
        }
      }
    }

    .inNetwork {
      display: flex;
      align-items: center;

      > p {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        color: #3042d4;
      }

      > img {
        margin: 0 0 0 14px;
      }

      > button {
        all: inherit;
        cursor: pointer;
        padding: 0 24px;
        margin-right: -24px;
      }
    }
  }

  .h-line {
    width: 100%;
    height: 1px;
    background-color: $blue_light;
    margin: 40px 0 48px 0;
  }

  .myNetwork {
    margin-bottom: 80px;
  }
}