.myEventsContainer .headerCharity {
  margin-bottom: 60px; }
  .myEventsContainer .headerCharity .headerLeft h4 {
    color: #3042d4;
    margin: 0px; }
  .myEventsContainer .headerCharity .headerLeft h2 {
    margin: 0px; }
  .myEventsContainer .headerCharity .headerRight {
    text-align: right; }
    .myEventsContainer .headerCharity .headerRight .btnCreateEvent {
      margin-left: 10px; }

.myEventsContainer .listEvents {
  margin-top: 80px; }

.myEventsContainer .savedEventsContainer {
  margin-top: 80px;
  background-color: #faf7f5; }
  .myEventsContainer .savedEventsContainer .internalContent {
    padding: 100px 0px; }

@media screen and (max-width: 1169px) {
  .myEventsContainer .savedEventsContainer .internalContent {
    padding: 100px 15px; } }

@media screen and (max-width: 767px) {
  .myEventsContainer .headerCharity .headerRight {
    text-align: left;
    margin-top: 20px; }
    .myEventsContainer .headerCharity .headerRight .btnDefault {
      display: block !important; }
    .myEventsContainer .headerCharity .headerRight .btnCreateEvent {
      margin-left: 0px;
      margin-top: 10px; } }
