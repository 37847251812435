.onboardingContainer {
  box-sizing: border-box;
  overflow: auto;
  background-color: #f3f5f7; }
  .onboardingContainer .onboardingFinishIcon {
    width: 130px;
    height: auto;
    margin: 15px 0; }
  .onboardingContainer h1 {
    font-family: 'Petrona' !important;
    font-style: normal;
    font-weight: 800;
    font-size: 36px;
    line-height: 41px;
    color: #212b35; }
    .onboardingContainer h1 span {
      font-family: 'Petrona' !important;
      color: #3042d4;
      font-size: 36px; }
  .onboardingContainer h2 {
    font-family: 'Petrona' !important;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 22px;
    color: #000000; }
  .onboardingContainer p,
  .onboardingContainer input,
  .onboardingContainer button {
    font-family: 'Poppins' !important;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 22px; }
  .onboardingContainer * {
    box-sizing: border-box; }
  .onboardingContainer .contentContainer {
    padding: 65px; }
    .onboardingContainer .contentContainer .content {
      background-color: #ffffff;
      border-radius: 16px;
      max-width: 1030px;
      width: 100%;
      margin: 0 auto;
      padding: 30px;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center; }
      .onboardingContainer .contentContainer .content > * {
        flex-shrink: 0; }
      .onboardingContainer .contentContainer .content p {
        white-space: break-spaces; }
      .onboardingContainer .contentContainer .content .headTitle {
        margin-bottom: 24px; }
      .onboardingContainer .contentContainer .content .input {
        width: 300px; }
      .onboardingContainer .contentContainer .content .inputsContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 24px; }
      .onboardingContainer .contentContainer .content .profilesContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 24px; }
        .onboardingContainer .contentContainer .content .profilesContainer .profile {
          padding: 18px;
          border: 1px solid #e3e5e5;
          border-radius: 16px;
          width: 300px;
          cursor: pointer;
          transition: 0.3s ease;
          position: relative; }
          .onboardingContainer .contentContainer .content .profilesContainer .profile .checkIcon {
            position: absolute;
            top: 16px;
            right: 20px; }
            .onboardingContainer .contentContainer .content .profilesContainer .profile .checkIcon.volunteer {
              right: unset;
              left: 20px; }
          .onboardingContainer .contentContainer .content .profilesContainer .profile p {
            margin: 0; }
          .onboardingContainer .contentContainer .content .profilesContainer .profile img {
            max-height: 60px; }
          .onboardingContainer .contentContainer .content .profilesContainer .profile.active {
            background: #3042d4;
            border: 1px solid #e3e5e5; }
            .onboardingContainer .contentContainer .content .profilesContainer .profile.active p {
              color: white; }
      .onboardingContainer .contentContainer .content .checkboxContainer {
        display: flex;
        align-items: center;
        justify-content: center; }
        .onboardingContainer .contentContainer .content .checkboxContainer .checkbox {
          display: block;
          cursor: pointer;
          user-select: none;
          position: relative;
          width: 24px;
          height: 24px;
          margin-right: 14px;
          border-radius: 4px;
          overflow: hidden;
          border: 1px solid #6b4eff;
          flex-shrink: 0; }
          .onboardingContainer .contentContainer .content .checkboxContainer .checkbox input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            width: 0;
            height: 0; }
            .onboardingContainer .contentContainer .content .checkboxContainer .checkbox input:checked ~ .checkmark {
              background-color: #3042d4; }
              .onboardingContainer .contentContainer .content .checkboxContainer .checkbox input:checked ~ .checkmark:after {
                display: block; }
          .onboardingContainer .contentContainer .content .checkboxContainer .checkbox .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            width: 24px;
            height: 24px;
            background-color: white; }
            .onboardingContainer .contentContainer .content .checkboxContainer .checkbox .checkmark:after {
              content: '';
              position: absolute;
              display: none;
              left: 8px;
              top: 4px;
              width: 6px;
              height: 12px;
              border: solid white;
              border-width: 0 2px 2px 0;
              -webkit-transform: rotate(45deg);
              -ms-transform: rotate(45deg);
              transform: rotate(45deg); }
      .onboardingContainer .contentContainer .content .button {
        padding: 10px 30px !important;
        background-color: #3042d4;
        border-radius: 10px;
        font-size: 16px;
        line-height: 24px;
        color: #ffffff;
        outline: 0;
        border: 0;
        font-weight: 400;
        border: 1px solid #3042d4; }
      .onboardingContainer .contentContainer .content .progresses {
        display: flex;
        position: relative; }
        .onboardingContainer .contentContainer .content .progresses .progress {
          width: 162px; }
          .onboardingContainer .contentContainer .content .progresses .progress > p {
            color: #b5bdc4; }
          .onboardingContainer .contentContainer .content .progresses .progress .line {
            width: 100%;
            height: 4px;
            background-color: transparent;
            border-radius: 100px;
            position: relative;
            z-index: 1; }
          .onboardingContainer .contentContainer .content .progresses .progress.active > p {
            color: black; }
          .onboardingContainer .contentContainer .content .progresses .progress.active .line {
            width: calc(100% + 4px);
            left: -4px;
            background-color: #3042d4; }
            .onboardingContainer .contentContainer .content .progresses .progress.active .line.first {
              left: 0;
              width: 100%; }
        .onboardingContainer .contentContainer .content .progresses .fullLine {
          position: absolute;
          width: 100%;
          height: 4px;
          background-color: #e4eaf7;
          border-radius: 100px;
          bottom: 0; }
      .onboardingContainer .contentContainer .content .select {
        border: 1px solid #6e7581;
        border-radius: 8px;
        color: #6e7581;
        font-family: 'Poppins' !important;
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        padding: 14px 16px;
        width: 100%;
        max-width: 390px;
        appearance: none;
        background: transparent;
        background-image: url("data:image/svg+xml,%3Csvg width='20' height='11' viewBox='0 0 20 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L10 10L19 1' stroke='%23B5BDC4' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: right 16px center;
        outline: 0;
        cursor: pointer; }
        .onboardingContainer .contentContainer .content .select.active {
          font-weight: 600; }
      .onboardingContainer .contentContainer .content .informationContainer {
        display: flex;
        width: 100%;
        padding: 0 64px; }
        .onboardingContainer .contentContainer .content .informationContainer.noSidebar {
          flex-wrap: wrap;
          justify-content: center; }
          .onboardingContainer .contentContainer .content .informationContainer.noSidebar .informationContent {
            width: 90%;
            text-align: center;
            justify-content: center; }
            .onboardingContainer .contentContainer .content .informationContainer.noSidebar .informationContent .chipsComponent.wrapContainer {
              justify-content: center; }
          .onboardingContainer .contentContainer .content .informationContainer.noSidebar .buttonContainer {
            margin: 15px 0; }
        .onboardingContainer .contentContainer .content .informationContainer .informationContent {
          width: 67%;
          text-align: left; }
        .onboardingContainer .contentContainer .content .informationContainer .charities {
          display: flex;
          flex-wrap: wrap;
          gap: 15px;
          max-height: 280px;
          overflow-y: scroll;
          margin-bottom: 30px; }
          .onboardingContainer .contentContainer .content .informationContainer .charities .charity {
            all: unset;
            flex: 0 0 18%;
            cursor: pointer;
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center; }
            .onboardingContainer .contentContainer .content .informationContainer .charities .charity .picture {
              width: 90px;
              height: 90px;
              border: 1px solid #ebebeb;
              border-radius: 50%; }
            .onboardingContainer .contentContainer .content .informationContainer .charities .charity .check {
              position: absolute;
              right: 8px;
              top: 0; }
            .onboardingContainer .contentContainer .content .informationContainer .charities .charity .logo {
              width: 88px;
              height: 88px;
              border-radius: 50%;
              background-color: #f7f5f0;
              margin: 0 auto; }
            .onboardingContainer .contentContainer .content .informationContainer .charities .charity p {
              text-align: center; }
            .onboardingContainer .contentContainer .content .informationContainer .charities .charity.active .picture {
              border: 2px solid #3042d4; }
        .onboardingContainer .contentContainer .content .informationContainer .line {
          background-color: #eeeeee;
          width: 1px;
          height: 298px;
          margin: 0 32px; }
        .onboardingContainer .contentContainer .content .informationContainer .circleContainer {
          min-width: 298px;
          width: 33%;
          flex-shrink: 0; }
          .onboardingContainer .contentContainer .content .informationContainer .circleContainer .circle {
            width: 296px;
            height: 296px;
            border-radius: 50%;
            background-color: #f7f5f0;
            margin-bottom: 56px; }
            .onboardingContainer .contentContainer .content .informationContainer .circleContainer .circle.rebecca {
              background-color: #89cbbf; }
            .onboardingContainer .contentContainer .content .informationContainer .circleContainer .circle.jonathan {
              background-color: #1a3465;
              color: white; }
            .onboardingContainer .contentContainer .content .informationContainer .circleContainer .circle > p {
              font-family: 'Petrona' !important;
              font-style: normal;
              font-weight: 800;
              font-size: 20px;
              line-height: 23px;
              text-align: center;
              margin: 16px 0;
              padding: 0 34px; }
            .onboardingContainer .contentContainer .content .informationContainer .circleContainer .circle .by {
              display: flex;
              align-items: center; }
              .onboardingContainer .contentContainer .content .informationContainer .circleContainer .circle .by > img {
                margin-right: 8px; }
              .onboardingContainer .contentContainer .content .informationContainer .circleContainer .circle .by .byName p {
                margin: 0;
                text-align: left; }
                .onboardingContainer .contentContainer .content .informationContainer .circleContainer .circle .by .byName p:nth-child(1) {
                  font-weight: 400;
                  font-size: 15px;
                  line-height: 22px; }
                .onboardingContainer .contentContainer .content .informationContainer .circleContainer .circle .by .byName p:nth-child(2) {
                  font-weight: 300;
                  font-size: 12px;
                  line-height: 18px; }
        .onboardingContainer .contentContainer .content .informationContainer .buttonContainer {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 16px; }
          .onboardingContainer .contentContainer .content .informationContainer .buttonContainer .button {
            width: 134px; }
          .onboardingContainer .contentContainer .content .informationContainer .buttonContainer .button.back {
            background-color: transparent;
            border: 1px solid #3042d4;
            color: #3042d4; }
      .onboardingContainer .contentContainer .content .location {
        display: flex;
        align-items: center;
        justify-content: center; }
        .onboardingContainer .contentContainer .content .location > p {
          text-decoration: underline; }
      .onboardingContainer .contentContainer .content .input-location {
        position: relative; }
        .onboardingContainer .contentContainer .content .input-location .input {
          width: 356px;
          border-radius: 48px;
          padding-left: 48px; }
        .onboardingContainer .contentContainer .content .input-location > img {
          position: absolute;
          left: 16px;
          top: 14px; }
      .onboardingContainer .contentContainer .content .hLine {
        width: 100%;
        max-width: 874px;
        height: 1px;
        margin: 40px auto;
        background-color: #eeeeee; }
    .onboardingContainer .contentContainer .newLocationContainer {
      display: block;
      min-width: 356px; }
      .onboardingContainer .contentContainer .newLocationContainer input[name=googleInput] {
        border: 1px solid black;
        border-radius: 20px;
        font-size: 16px;
        line-height: 1.13;
        height: 40px;
        outline: none; }
  @media screen and (max-width: 767px) {
    .onboardingContainer h1 {
      font-size: 32px;
      margin-bottom: 0; }
    .onboardingContainer .contentContainer {
      padding: 0; }
      .onboardingContainer .contentContainer .content {
        padding: 60px 28px; }
        .onboardingContainer .contentContainer .content p {
          white-space: unset; }
        .onboardingContainer .contentContainer .content .headTitle {
          margin-bottom: 32px; }
        .onboardingContainer .contentContainer .content .inputsContainer {
          flex-direction: column;
          gap: 18px; }
          .onboardingContainer .contentContainer .content .inputsContainer .input {
            margin-bottom: 0; }
        .onboardingContainer .contentContainer .content .profilesContainer {
          flex-direction: column; }
          .onboardingContainer .contentContainer .content .profilesContainer .profile {
            display: flex;
            align-items: center;
            padding-left: 38px; }
            .onboardingContainer .contentContainer .content .profilesContainer .profile > img {
              width: 48px;
              margin-bottom: 0;
              margin-right: 16px; }
            .onboardingContainer .contentContainer .content .profilesContainer .profile > p {
              margin-bottom: 0;
              font-size: 12px;
              line-height: 21px;
              text-align: left; }
            .onboardingContainer .contentContainer .content .profilesContainer .profile .checkIcon {
              width: auto;
              left: 10px !important;
              top: 10px !important;
              right: unset; }
        .onboardingContainer .contentContainer .content .checkboxContainer {
          align-items: flex-start;
          margin-top: 14px; }
          .onboardingContainer .contentContainer .content .checkboxContainer p {
            text-align: left;
            margin-top: 0; }
        .onboardingContainer .contentContainer .content .informationContainer {
          padding: 0;
          flex-direction: column; }
          .onboardingContainer .contentContainer .content .informationContainer .informationContent {
            width: 100%;
            margin-bottom: 64px;
            text-align: center; }
          .onboardingContainer .contentContainer .content .informationContainer.noSidebar .informationContent {
            width: 100%;
            margin-bottom: 30px; }
          .onboardingContainer .contentContainer .content .informationContainer.noSidebar .buttonContainer {
            margin: 30px 0; }
          .onboardingContainer .contentContainer .content .informationContainer .line {
            display: none; }
          .onboardingContainer .contentContainer .content .informationContainer .circleContainer {
            width: 100%; }
            .onboardingContainer .contentContainer .content .informationContainer .circleContainer .circle {
              display: none; }
          .onboardingContainer .contentContainer .content .informationContainer .charities .charity {
            flex: 0 0 28%; } }
