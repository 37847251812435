.autoCompleteComponent {
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .autoCompleteComponent .inline {
    display: inline-block; }
  .autoCompleteComponent .loadingComponent {
    top: 10px;
    right: 20px; }
  .autoCompleteComponent .helpText {
    position: absolute;
    margin-top: 110px; }

@-moz-document url-prefix() {
  .autoCompleteComponent .helpText {
    top: 30px; } }
