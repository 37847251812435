.verifyEmailContainer {
  max-width: 700px; }
  .verifyEmailContainer .content {
    padding: 40px 100px; }
    .verifyEmailContainer .content h1 {
      color: #3042d4;
      margin-bottom: 20px; }
    .verifyEmailContainer .content p {
      margin-top: 60px; }
    .verifyEmailContainer .content .buttonsContainer {
      margin-top: 60px; }
