@import 'src/styles/_defines';

.tasksListContainer {
  padding: 50px 30px;

  .linkTitle {
    color: #000000;
    font-size: 14px;
    font-weight: 500;
    text-decoration: underline;

    &:hover {
      font-weight: 600;
      color: #000000;
      cursor: pointer;
    }
  }

  .multiTitle {
    color: #000000;
    font-size: 20px;
    font-weight: 500;
    text-decoration: none;

    span:hover {
      font-weight: 600;
      color: #000000;
      text-decoration: none !important;
      cursor: auto;
    }
  }

  .linkGreyTitle {
    color: #919ca7;
    font-size: 14px;
    font-weight: 500;
    text-decoration: underline;

    &:hover {
      font-weight: 600;
      color: #919ca7;
      cursor: pointer;
    }
  }

  .sectionTitle {
    margin: 30px 20px;
    font-family: Poppins;
    font-size: 22px;
    font-weight: 700;
    line-height: 33px;
    letter-spacing: 0em;
    text-align: left;
  }

  .volunteerProperty {
    font-family: Poppins;
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;

    span {
      font-size: 16px !important;
      font-weight: 400 !important;
    }
  }
}
