.forgotPasswordContainer {
  background-color: #f3f5f7;
  min-height: calc(100vh - 60px - 256px); }
  .forgotPasswordContainer .forgotPasswordContainerInternal {
    max-width: 610px; }
    .forgotPasswordContainer .forgotPasswordContainerInternal .emailSentContainer h2 {
      margin-top: 40px;
      margin-bottom: 40px; }
  @media screen and (max-width: 767px) {
    .forgotPasswordContainer .centredContainer {
      margin: 0px 30px; } }
