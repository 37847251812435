.finishContainer {
  background-color: #f1f3f6;
  text-align: center;
  padding-bottom: 80px;
  padding-top: 180px; }
  .finishContainer .finishContainerInternal {
    display: flex;
    max-width: 1030px;
    background-color: #ffffff;
    padding: 20px 60px 50px;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
    border-radius: 16px; }
  .finishContainer .finishCharityHeader {
    color: #000000;
    max-width: 600px;
    font-weight: 800; }
    .finishContainer .finishCharityHeader span {
      font-size: 36px;
      font-family: 'Petrona' !important;
      color: #3042d4; }
  .finishContainer .imgCharity {
    display: block;
    width: 126px;
    height: 126px;
    border-radius: 50%;
    background-color: #bdbdbd;
    margin-bottom: 30px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-left: auto;
    margin-right: auto; }
  .finishContainer .viewContent p {
    font-size: 14px;
    font-weight: 300;
    font-family: 'Poppins'; }
  .finishContainer .divider {
    width: 100%; }
  .finishContainer .buttonsContainer h5 {
    font-family: 'Poppins' !important;
    font-weight: 700;
    font-size: 14px;
    margin-bottom: 0; }
  .finishContainer .buttonsContainer p {
    font-size: 14px;
    margin: 20px 0 30px; }
  @media screen and (max-width: 767px) {
    .finishContainer {
      padding: 30px; } }
  @media screen and (min-width: 1170px) {
    .finishContainer .finishContainerInternal {
      width: 1030px; } }
