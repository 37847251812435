@import "src/styles/_defines";

.taskDetails {
  padding-top: 60px;
  position: relative;

  .headerBackground {
    background-color: #D3DDF3;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: -1;
    height: 400px;
  }

  .charityLogo {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
    border: 1px solid #E3E5E5;
  }

  .taskDetailsContainer {
    background-color: white;
    border-radius: 8px;
    padding: 40px;

    @media screen and (max-width: $screen-xs-max) {
      padding: 0;
    }
  }
}