.chipsComponent {
  /*
  &.disabledChips {
    .chip {
      background-color: $white !important;
      border: 1px solid $tertiary_body !important;
      span {
        color: $tertiary_body !important;
      }
      cursor: auto !important;
    }
  }
  */ }
  .chipsComponent.wrapContainer {
    display: flex;
    flex-wrap: wrap; }
  .chipsComponent.roundedChips .chip.isClickable {
    cursor: pointer !important;
    box-shadow: none !important;
    border-radius: 20px !important;
    padding: 0 8px !important; }
  .chipsComponent .chip {
    margin: 0px 12px 12px 0px !important;
    display: flex;
    flex-wrap: wrap;
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    cursor: auto !important; }
    .chipsComponent .chip .chipTitle {
      font-family: 'Poppins' !important;
      font-weight: 400 !important;
      font-size: 14px; }
    .chipsComponent .chip.hasImage span {
      padding: 0px !important; }
    .chipsComponent .chip.hasImage .imgUser {
      width: 32px;
      height: 32px;
      user-select: none;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      margin-right: -4px;
      vertical-align: middle; }
    .chipsComponent .chip.hasImage .chipTitle {
      margin: 0px 12px; }
    .chipsComponent .chip.isClickable {
      cursor: pointer !important;
      box-shadow: none !important;
      border-radius: 5px !important;
      padding: 0 8px !important; }
  @media screen and (max-width: 1169px) {
    .chipsComponent .chip {
      flex-wrap: nowrap; } }
