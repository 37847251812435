@import "src/styles/_defines";

.settingsContainer {
    background-color: $grey_bg;
    .centredContainer {
        max-width: 680px;
    }

    .firstnameContainer {
        padding-left: 0px;
    }

    .lastnameContainer {
        padding-right: 0px;
    }

    .accountContainer {
        margin-top: 70px;
        .contentAccount {
            display: flex;
            flex-direction: column;
            a {
                margin: 10px 0px;
                font-weight: 700;
            }
        }
    }
    .buttonsContainer {
        margin-top: 80px;
        text-align: center;
    }

    @media screen and (max-width: $screen-xs-max) {
        .accountContainer {
            margin-top: 20px;
        }
        .firstnameContainer {
            padding: 0px;
        }
        .lastnameContainer {
            padding: 0px;
        }
    }
}

.settings {
  height: calc(100vh - 60px);
  max-height: calc(100vh - 60px);
  overflow: hidden;
  display: flex;
  
  h2 {
    font-family: Petrona !important;
    font-size: 26px;
    font-weight: 800;
    line-height: 29px;
  }
  .sidebar {
    font-size: 14px;
    width: 196px;
    border-right: 1px solid #e4eaf7;
    overflow-y: scroll;
    height: 100%;
    background-color: #f3f5f6;
    padding-top: 22px;
    
    .sidebarSectionContainer {
      display: flex;
      flex-direction: column;
      margin-top: 39px;
      
      .menu {
        text-decoration: none;
        text-align:start;
        color: #1A3465;
        width: 100%;
        height: 50px;
        padding-left: 36px;
        
        &.selected {
          font-weight: 600;
        }
        
        &.header {
          font-weight: 700;
        }
      }
    }
  }
}

.settingsPanel {
  padding-top: 63px;
  padding-left: 75px;
  max-width: 722px;
  &.details {
    overflow: scroll !important;
    height: calc(100vh - 60px - 60px)
  }

  h3 {
    font-family: Poppins !important;
    font-size: 16px;
    font-weight: 700;
    line-height: 28px;
  }

  p {
    font-size: 14px;
    margin-bottom: 22px;
  }

  div {
    padding-left: 0px;
    margin-left: 0px;
  }

  .securityContainer {
    margin-bottom: 42px;
  }

  .deleteAccount {
    padding-top: 32px;
  }
}

.notificationPanel {
  padding-top: 63px;
  padding-left: 75px;

  .contentBlock {
    display: flex;
    flex-direction: column;
    width: 618px;

    h3 {
      font-family: Poppins !important;
      font-size: 16px;
      font-weight: 700;
      line-height: 28px;
    }
    &.platform {
      margin-bottom: 57px;
    }
  }
}

.accessPanel {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 63px;
  padding-left: 75px;
  margin-bottom: 30px;
  min-width: 834px;
  height: calc(100vh - 63px - 30px);
  overflow-y: auto;
  .teamContainer {
    margin-bottom: 24px;
  }
  p {
      font-size: 14px;
      margin: 0px;
  }
  .headerBlock {
    display: flex;
    align-items: center;
    p {
        font-size: 16px;
        font-weight: 700;
        margin-left: 0px;
        margin-right: 30px;
    }
  }
  .contentBlock {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    margin-top: 21px;
    .memberTable {
      margin-top: 27px;
      .tableHeader {
        display: flex;
        flex-direction: row; 
        .headerItem {
          &:nth-child(1) {
            width: 230px;
          }
          &:nth-child(2) {
            width: 267px;
          }
          &:nth-child(3) {
            width: 190px;
          }
        }
      }
      hr {
        border-color: black;
      }
      .tableBody {
        display: flex;
        flex-direction: column;
        .textGray {
            color: #AAB0B5;
          }
        .tableRow {
          display: flex;
          flex-direction: row;
          align-items: center;
          width: 100%;
          height: 60px;
          .cell {
            display: flex;
            &.name {
              width: 230px;
              img {
                width: 30px;
                height: 30px;
                border-radius: 50%;
                margin-right: 17px;
              }
              span {
                margin-left: 11px;
              }
            }
            &.email {
              width: 267px;
            }
            &.role {
              width: 190px;
              .select {
                border: 1px solid #e3e5e6;
                border-radius: 8px;
                color: #AAB0B5;
                height: 34px;
                width: 188px;
              }
            }
            &.seat {
              margin-left: 30px;
            }
          }
        }
      }
      .roleErrorText {
        color: #ED543D;
        text-align: right;
      }
    }
  }
}