@import 'src/styles/_defines';

.messagesComponent {
  .messageHeader {
    height: 60px;
    border-bottom: 1px solid $blue_light;
    padding: 0px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    h2 {
      font-weight: 600;
    }
    .messageContextContainer {
      border: 1px solid $blue_solid_light;
      min-width: 60px;
      border-radius: 10px;
      padding: 4px 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      text-decoration: none;
      img {
        width: 21px;
        height: 21px;
      }
      span {
        font-size: 16px;
        line-height: 10px;
        font-weight: 600;
      }
      &:hover {
        background-color: $grey_lighter;
      }
    }
  }
  .messagesContentContainer {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: calc(100vh - 60px - 60px);
    .messagesContent {
      padding: 0px 30px;
      height: 100%;
      overflow: scroll;
      display: flex;
      flex-direction: column-reverse;
      &.isScrolled {
        scroll-behavior: smooth;
      }
    }
    .dateContainer {
      display: flex;
      flex-direction: column;
      .dateTitleContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
        .dateTitle {
          font-size: 10px;
          color: $secondary_body;
        }
      }
      .messageBox {
        display: flex;
        margin-bottom: 25px;
        img {
          width: 36px;
          height: 36px;
          border-radius: 18px;
          object-fit: contain;
        }
        .messageBoxContent {
          margin-left: 16px;
          .nameContent {
            margin: 0;
            font-size: 14px;
            font-weight: 600;
            span {
              font-weight: 400;
            }
          }
          .body {
            font-size: 14px;
            white-space: pre-line;
            word-break: break-word;
            -webkit-hyphens: auto;
            -ms-hyphens: auto;
            hyphens: auto;
            margin: 7px 0px;
            a {
              max-width: 100%;
              overflow-x: hidden;
              text-overflow: ellipsis;
              display: inline-block;
              white-space: nowrap;
              vertical-align: bottom;
            }
          }
        }
      }
    }
    #anchorPoint {
      opacity: 0;
      cursor: default;
    }
    .messageTextBox {
      border: 1px solid $blue_solid_light;
      border-radius: 10px;
      height: 90px;
      margin: 15px 25px 35px 25px;
      display: flex;
      justify-content: space-between;
      overflow: hidden;
      .actionsContainer {
        margin: 12px;
        display: flex;
        justify-content: flex-end;
        position: absolute;
        right: 25px;
        bottom: 34px;
        .sendMessageButton {
          width: 28px;
          height: 28px;
          border-radius: 14px;
          background-color: $primary_color;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          &:hover {
            opacity: 0.8;
          }
          &.disabled {
            background-color: $blue_solid_light;
            cursor: default;
            &:hover {
              opacity: 1;
            }
          }
          img {
            width: 20px;
            margin-right: 2px;
          }
        }
      }
    }
  }
  .genericMessagesContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: calc(100vh - 60px - 60px);
    max-width: 400px;
    margin: auto;
    h2 {
      font-size: 14px;
      font-weight: 600;
      margin: 0;
      text-align: center;
    }
    p {
      font-size: 14px;
      text-align: center;
      color: $secondary_body;
    }
    img {
      width: 150px;
      margin-top: 18px;
    }
    .macroName {
      color: $black;
    }
  }
}
