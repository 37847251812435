@import 'src/styles/_defines';

.continueTaskContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 0px !important;

  div div .modalPaper {
    width: 393px;
  }

  .iconButton {
    display: none !important;
  }

  .subject {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;
    color: black;
  }

  .btn_continue_task_cover {
    display: flex;
    justify-content: center;

    .btn_continue_task {
      margin-top: 23px;

      button {
        background-color: $green_dark !important;
      }
    }
  }
}
