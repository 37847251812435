.loginContainer {
  background-color: #f3f5f7;
  min-height: calc(100vh - 60px - 256px); }
  .loginContainer .centredContainer {
    max-width: 610px; }
  .loginContainer .loginContainerInternal .contentContainer {
    margin-left: auto;
    margin-right: auto;
    text-align: left; }
  .loginContainer .loginContainerInternal p {
    color: #637381; }
  .loginContainer .loginContainerInternal .buttonsContainer {
    margin: 20px 0;
    display: flex;
    align-items: center;
    justify-content: center; }
  .loginContainer .loginContainerInternal .facebookLogo {
    margin-bottom: 5px;
    margin-right: 5px; }
  .loginContainer .loginContainerInternal .forgotPassword {
    margin-top: -5px;
    display: inline-block;
    text-decoration: none; }
    .loginContainer .loginContainerInternal .forgotPassword:hover {
      text-decoration: underline; }
  .loginContainer .loginContainerInternal .loginButton {
    margin: 20px 0; }
  @media screen and (max-width: 767px) {
    .loginContainer .centredContainer {
      margin: 0px 30px; }
    .loginContainer .loginContainerInternal .contentContainer {
      width: auto; } }
