.fileUploadComponent img {
  width: 100%;
  border-radius: 50%; }

.fileUploadComponent .dropzone {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #faf7f5 !important;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; }
  .fileUploadComponent .dropzone .previewImageContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center; }
    .fileUploadComponent .dropzone .previewImageContainer .cameraIcon {
      color: #20c1c9 !important;
      margin-bottom: 10px; }
    .fileUploadComponent .dropzone .previewImageContainer .label {
      margin: 0px;
      margin-top: 5px;
      font-family: 'Poppins';
      font-weight: lighter;
      font-size: 12px; }
  .fileUploadComponent .dropzone .instructions {
    color: #b5bdc4;
    margin: 5px; }
  .fileUploadComponent .dropzone .editButtons {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2) !important;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    display: none; }
    .fileUploadComponent .dropzone .editButtons p {
      margin: 0px;
      color: #ffffff; }
  .fileUploadComponent .dropzone.hasImage:hover {
    border: 3px solid #3042d4; }
    .fileUploadComponent .dropzone.hasImage:hover .editButtons {
      display: flex; }
