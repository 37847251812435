.skillListContainer {
  margin: -4px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  .skillBadge {
    padding: 4px 8px;
    border-radius: 4px;
    margin: 4px;
  }

  .macroBadge {
    padding: 8px 16px;
    border-radius: 4px;
    margin: 4px;
  }
}
