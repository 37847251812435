.eventThumbnailComponent {
  text-decoration: none;
  margin-bottom: 40px;
  cursor: pointer; }
  .eventThumbnailComponent:hover {
    text-decoration: none;
    color: inherit; }
  .eventThumbnailComponent .internalContainer {
    background-color: #ffffff;
    height: 436px; }
    .eventThumbnailComponent .internalContainer .header {
      height: 183px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover; }
      .eventThumbnailComponent .internalContainer .header .date {
        top: 20px;
        background-color: #ffffff;
        color: #637381;
        font-weight: bold;
        width: 64px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        position: absolute; }
      .eventThumbnailComponent .internalContainer .header img {
        height: 100%;
        width: 100%; }
      .eventThumbnailComponent .internalContainer .header .favoriteContainer {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background-color: #ffffff;
        position: absolute;
        right: 25px;
        top: 20px;
        display: flex;
        align-items: center;
        justify-content: center; }
        .eventThumbnailComponent .internalContainer .header .favoriteContainer .favoriteIcon {
          color: #f53920;
          box-shadow: 0px 0px rgba(0, 0, 0, 0.3); }
      .eventThumbnailComponent .internalContainer .header .barContainer {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 32px;
        opacity: 0.9;
        text-align: center;
        padding: 8px;
        left: 15px;
        right: 15px;
        top: 151px;
        text-decoration: none; }
        .eventThumbnailComponent .internalContainer .header .barContainer h5 {
          margin: 0px;
          color: #ffffff; }
      .eventThumbnailComponent .internalContainer .header .attendingContainer {
        background-color: #B5BDC4; }
        .eventThumbnailComponent .internalContainer .header .attendingContainer .checkIcon {
          width: 18px !important;
          height: 18px !important;
          margin-right: 5px; }
      .eventThumbnailComponent .internalContainer .header .reviewVolunteersContainer {
        background-color: #B26B78; }
      .eventThumbnailComponent .internalContainer .header .isFullContainer {
        background-color: #E63E27; }
    .eventThumbnailComponent .internalContainer .content {
      height: 193px;
      padding: 20px;
      border-left: 1px solid #e4eaf7;
      border-right: 1px solid #e4eaf7; }
      .eventThumbnailComponent .internalContainer .content .title {
        margin-top: 10px;
        margin-bottom: 0px;
        color: #212b35;
        font-weight: bold; }
      .eventThumbnailComponent .internalContainer .content .location {
        margin: 0px;
        text-transform: uppercase; }
      .eventThumbnailComponent .internalContainer .content .category, .eventThumbnailComponent .internalContainer .content .location {
        font-weight: bold; }
      .eventThumbnailComponent .internalContainer .content .category {
        color: #20c1c9; }
        .eventThumbnailComponent .internalContainer .content .category:hover {
          color: #20c1c9; }
      .eventThumbnailComponent .internalContainer .content .location {
        color: #637381; }
        .eventThumbnailComponent .internalContainer .content .location:hover {
          color: #637381; }
    .eventThumbnailComponent .internalContainer .footer {
      height: 60px;
      display: flex;
      border: 1px solid #e4eaf7; }
      .eventThumbnailComponent .internalContainer .footer .imgCharity {
        border-right: 1px solid #e4eaf7;
        width: 60px;
        display: flex;
        align-items: center;
        justify-content: center; }
        .eventThumbnailComponent .internalContainer .footer .imgCharity .image {
          width: 42px;
          height: 42px;
          border-radius: 50%;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center; }
      .eventThumbnailComponent .internalContainer .footer .charityName {
        display: flex;
        align-items: center;
        margin-left: 15px;
        color: #20c1c9; }
