@import "src/styles/_defines";

.opportunityCard {
  padding: 32px 24px;
  border-radius: 8px;
  border: 1px solid $grey_border;

  .charityLogo {
    height: 66px;
    width: 66px;
  }
}