@import "src/styles/_defines";

.flatButton {
  height: 40px !important;
  line-height: 40px !important;
  border-radius: 20px !important;
  display: flex !important;
  align-items: center;
  justify-content: center;

  span {
    color: $primary_color;
    text-transform: none !important;
    font-weight: 700 !important;
    font-size: 14px !important;
    font-family: "Poppins" !important;
    white-space: nowrap;
  }

  &.flatBordered {
    border: 1px solid $primary_color !important
  }

  &.flatFilled {
    span {
      color: white !important;
    }
  }

  img {
    margin-left: 16px !important;
  }

  &.size-large {
    span {
      font-size: 16px !important;
      padding-left: 24px !important;
      padding-right: 24px !important;
    }
  }
}