.createAmbassadorContainer h3 {
  margin-bottom: 0px; }

.createAmbassadorContainer .contentContainer {
  padding-top: 90px;
  padding-bottom: 90px;
  background-color: #f1f3f6; }

.createAmbassadorContainer .headerCreateAmbassador {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto; }

.createAmbassadorContainer .createAmbassadorContent {
  margin-top: 50px; }
  .createAmbassadorContainer .createAmbassadorContent .leftContainer {
    background-color: #ffffff;
    padding: 10px 30px; }
  .createAmbassadorContainer .createAmbassadorContent .rightContainer {
    margin-left: 25px; }
  .createAmbassadorContainer .createAmbassadorContent .rightContainerInternal {
    background-color: #ffffff;
    padding: 10px 30px 30px 30px; }
  .createAmbassadorContainer .createAmbassadorContent .bioHelper {
    display: flex;
    flex-direction: row;
    justify-content: space-between; }
    .createAmbassadorContainer .createAmbassadorContent .bioHelper .count {
      color: #B5BDC4; }
  .createAmbassadorContainer .createAmbassadorContent .uploadPictureContainer {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center; }
    .createAmbassadorContainer .createAmbassadorContent .uploadPictureContainer .dropzone {
      width: 270px !important;
      height: 270px !important; }

.createAmbassadorContainer .buttonsContainer {
  margin-top: 40px;
  text-align: center; }
  .createAmbassadorContainer .buttonsContainer .btnDefault {
    min-width: 149px !important; }

@media screen and (max-width: 767px) {
  .createAmbassadorContainer .createAmbassadorContent .rightContainer {
    margin-top: 40px;
    margin-left: 0px;
    padding: 0px; } }
