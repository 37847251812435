.membersModal {

  .divider {
    border-bottom: 1px solid #EAEAEB;
    height: 1px;
    margin-left: -72px;
    margin-right: -72px;
  }

  .membersContainer {
    margin: 0 -32px;
  }

  .modalBody {
    display: flex;
    flex-direction: column;
  }
}