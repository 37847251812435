.notification {
  position: fixed;
  z-index: 2001;
  top: 160px;
  left: 0;
  right: 0;
  background: #339175;
  text-align: center;
  line-height: 2.5;
  overflow: hidden;
  -webkit-transform: translateY(-100px);
  -webkit-animation: slideDown 10s 1.0s 1 ease forwards;
  -moz-transform: translateY(-100px);
  -moz-animation: slideDown 10s 1.0s 1 ease forwards;
  min-height: 66px;
  display: flex;
  align-items: center;
  justify-content: center; }
  .notification.close {
    display: none; }
  .notification .content {
    display: block; }
    .notification .content p {
      display: inline-block;
      color: white; }
    .notification .content .closeAction {
      position: fixed;
      right: 20px;
      top: 7px;
      padding: 15px; }

@-webkit-keyframes slideDown {
  0%,
  100% {
    -webkit-transform: translateY(-100px); }
  10%,
  90% {
    -webkit-transform: translateY(0px); } }

@-moz-keyframes slideDown {
  0%,
  100% {
    -moz-transform: translateY(-100px); }
  10%,
  90% {
    -moz-transform: translateY(0px); } }
