.shareModal {

  .modalPaper{
    border-radius: 24px !important;
    box-shadow: none !important;
  }

  .modalBody {
    padding: 72px !important;
  }

  h1 {
    font-size: 31px;
    color: black;
  }

  .linkContainer {
    color: black;
    padding: 16px 24px;
    border: 1px solid #E3E5E6;
    border-radius: 8px;
  }
}