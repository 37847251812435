.faqsContainer {
  margin-top: 60px; }
  .faqsContainer .header {
    height: 290px;
    background: #339175;
    background-image: url("../assets/header-bg-1.svg"), url("../assets/header-bg-2.svg");
    background-repeat: no-repeat;
    background-position: left 144px center, right 160px center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column; }
    .faqsContainer .header h1 {
      font-family: 'Petrona', sans-serif;
      font-style: normal;
      font-weight: 800;
      font-size: 60px;
      line-height: 68px;
      color: white;
      margin-top: 32px; }
    .faqsContainer .header p {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 17px;
      line-height: 116%;
      color: white; }
  .faqsContainer .helpTypeContainer {
    display: flex;
    justify-content: center;
    margin-top: 80px; }
    .faqsContainer .helpTypeContainer .helpType {
      width: 280px;
      border: 1px solid #3042d4;
      background-color: #ffffff;
      border-radius: 16px;
      text-align: center;
      padding: 34px;
      cursor: pointer; }
      .faqsContainer .helpTypeContainer .helpType p {
        margin-bottom: 0px;
        margin-top: 10px; }
      .faqsContainer .helpTypeContainer .helpType h2 {
        margin-top: 8px;
        margin-bottom: 0px;
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 116%; }
      .faqsContainer .helpTypeContainer .helpType.selected {
        background-color: #3042d4; }
        .faqsContainer .helpTypeContainer .helpType.selected h2,
        .faqsContainer .helpTypeContainer .helpType.selected p {
          color: #ffffff; }
  .faqsContainer .faqsContentContainer {
    padding-bottom: 90px; }
    .faqsContainer .faqsContentContainer p {
      word-break: break-word !important; }
    .faqsContainer .faqsContentContainer .faqsList {
      max-width: 870px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 50px;
      border: 1px solid #e7e9e9 !important; }
      .faqsContainer .faqsContentContainer .faqsList p {
        word-break: break-word !important; }
      .faqsContainer .faqsContentContainer .faqsList .listTitle {
        font-weight: 400;
        font-size: 17px;
        line-height: 116%;
        color: black;
        padding: 18px 0 18px 16px; }
      .faqsContainer .faqsContentContainer .faqsList .answer {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #6e7581;
        word-break: break-all;
        padding: 6px 32px 56px 32px;
        line-height: 1.8; }
        .faqsContainer .faqsContentContainer .faqsList .answer span {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #6e7581; }
        .faqsContainer .faqsContentContainer .faqsList .answer a {
          color: #6e7581; }
      .faqsContainer .faqsContentContainer .faqsList .defaultArrowStyle {
        fill: #212b35 !important;
        margin-top: 30px !important; }
      .faqsContainer .faqsContentContainer .faqsList .defaultDivider {
        background-color: #e7e9e9 !important; }
  .faqsContainer .getInTouchContainer {
    width: 100%;
    height: 516px;
    background-color: #f7f5f0;
    display: flex;
    align-items: center;
    justify-content: center; }
    .faqsContainer .getInTouchContainer .content {
      width: 100%;
      max-width: 885px;
      background-color: white;
      border-radius: 16px;
      padding: 56px 64px 24px 64px;
      display: flex;
      justify-content: space-between; }
      .faqsContainer .getInTouchContainer .content .information {
        width: 250px; }
        .faqsContainer .getInTouchContainer .content .information h1 {
          font-family: 'Petrona', sans-serif;
          font-style: normal;
          font-weight: 800;
          font-size: 36px;
          line-height: 41px;
          color: #000000;
          margin-bottom: 34px; }
        .faqsContainer .getInTouchContainer .content .information p {
          font-family: 'Europa', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 17px;
          line-height: 20px;
          color: #000000;
          white-space: break-spaces;
          margin-bottom: 44px; }
        .faqsContainer .getInTouchContainer .content .information .social-media {
          display: flex;
          align-items: center;
          gap: 24px; }
      .faqsContainer .getInTouchContainer .content .fields {
        width: 398px;
        text-align: right; }
        .faqsContainer .getInTouchContainer .content .fields > input {
          margin-bottom: 14px; }
        .faqsContainer .getInTouchContainer .content .fields > textarea {
          height: 104px;
          margin-bottom: 14px;
          resize: none; }
  @media screen and (max-width: 991px) {
    .faqsContainer .internalContainerStyle {
      padding: 20px; }
      .faqsContainer .internalContainerStyle .titleContainer {
        padding-top: 50px; } }
  @media screen and (max-width: 767px) {
    .faqsContainer .helpTypeContainer {
      flex-direction: column;
      align-items: center; }
      .faqsContainer .helpTypeContainer .helpType {
        margin-bottom: 10px; } }
  .faqsContainer .symbol {
    font-size: 7px !important;
    vertical-align: super; }
