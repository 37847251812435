.signUpContainer {
  background-color: #f1f3f6;
  min-height: calc(100vh - 60px - 256px); }
  .signUpContainer p {
    color: #637381; }
  .signUpContainer .signUpContainerInternal {
    max-width: 610px; }
    .signUpContainer .signUpContainerInternal .contentContainer {
      margin-left: auto;
      margin-right: auto;
      text-align: left; }
      .signUpContainer .signUpContainerInternal .contentContainer #g-recaptcha {
        margin: 15px auto !important;
        width: auto !important;
        height: auto !important;
        text-align: -webkit-center;
        text-align: -moz-center;
        text-align: -o-center;
        text-align: -ms-center; }
      .signUpContainer .signUpContainerInternal .contentContainer .signUpForm {
        display: flex;
        flex-direction: column;
        align-items: center; }
        .signUpContainer .signUpContainerInternal .contentContainer .signUpForm span {
          width: 100%; }
      .signUpContainer .signUpContainerInternal .contentContainer .facebookLogo {
        margin-bottom: 5px;
        margin-right: 5px; }
      .signUpContainer .signUpContainerInternal .contentContainer .buttonsContainer {
        width: fit-content; }
      .signUpContainer .signUpContainerInternal .contentContainer .termsContainer {
        display: flex;
        padding-top: 13px;
        width: 100%; }
        .signUpContainer .signUpContainerInternal .contentContainer .termsContainer .checkbox {
          width: fit-content !important; }
        .signUpContainer .signUpContainerInternal .contentContainer .termsContainer p {
          margin: 0px; }
    .signUpContainer .signUpContainerInternal .userRegisteredContainer h2 {
      margin-top: 50px;
      margin-bottom: 50px; }
    .signUpContainer .signUpContainerInternal .userRegisteredContainer .email {
      color: #20c1c9; }
    .signUpContainer .signUpContainerInternal .doLater {
      margin-top: 60px; }
  @media screen and (max-width: 767px) {
    .signUpContainer .signUpContainerInternal {
      margin: 0px 30px; }
      .signUpContainer .signUpContainerInternal .contentContainer {
        width: auto; } }
