.newMessageModal {
  padding-top: 100px !important;
  .modalPaper {
    .membersList {
      max-height: 300px;
      overflow-y: scroll;
      padding-top: 15px;
      .memberRow {
        padding: 12px;
        .selectUserImg {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}

.newForumModal {
  padding-top: 100px !important;
  
}
