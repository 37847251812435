@import 'src/styles/_defines';

.confirmCompleteTaskContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 0px !important;

  div div .modalPaper {
    width: 500px;
  }

  .iconButton {
    display: none !important;
  }

  .task_name {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    color: #3042d4;
    margin-bottom: 20px;
  }

  .subject {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;
    color: black;
  }

  .btn_complete_task_cover {
    display: flex;
    justify-content: center;

    .btn_complete_task_submission {
      margin-top: 23px;

      button {
        background-color: $green_dark !important;
      }
    }

    .btn_complete_task {
      margin-top: 23px;
      margin-right: 10px;

      button {
        background-color: $green_dark !important;
      }
    }

    .btn_review_timesheet {
      margin-top: 23px;
      margin-left: 10px;

      button {
        background-color: $primary_color !important;
      }
    }
  }
}
