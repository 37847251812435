.defaultListStyles {
  padding: 0px !important;
  background-color: #ffffff !important;
  border: 1px solid #e4eaf7 !important; }

.defaultDivider {
  background-color: #e4eaf7 !important; }

.defaultArrowStyle {
  color: #212b35 !important; }

.titlelist {
  margin: 0px; }
