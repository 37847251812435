@import 'src/styles/_defines';

.charitiesModal {

  > div > div {
    max-width: 960px !important;
  }

  .modalBody {
    display: flex;
    flex-direction: column;
  }

  .scrollContainer {
    flex: 1;
    overflow: auto;
  }

  .charityList {    
    .charity {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      .charityInner{
        .charityLogo {
          width: 64px;
          height: 64px;
        }

        .details {
          .charityName {
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
            margin: 0 0 8px 0;
          }
        }

        .causesList {
          margin-bottom: 8px;

          p {
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: #6e7581;
            margin: 0;
          }
        }

        .members p {
          font-size: 14px;
        }
      }
    }

    .h-line {
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }
}