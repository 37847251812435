@import url("https://fonts.googleapis.com/css2?family=Petrona:wght@700;800&family=Poppins:wght@300;400&display=swap");
* {
  -webkit-font-smoothing: antialiased; }

/* Container */
.centredContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  padding: 80px 0px; }

.defaultPageContainer {
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 100px;
  margin-bottom: 100px; }

.flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; }

.defaultMainRootContentContainer {
  max-width: 1170px;
  margin-left: auto;
  margin-right: auto; }

.internalContainerStyle {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  float: none; }

/* Backgrounds */
.bgWhite {
  background-color: #ffffff; }

.bgLightGray {
  background-color: #bdbdbd; }

.pvertical {
  padding-top: 15px;
  padding-bottom: 15px; }

.phorizontal {
  padding-right: 15px;
  padding-left: 15px; }

.p20 {
  padding: 20px; }

.p30 {
  padding: 30px; }

.p15 {
  padding: 15px; }

.p10 {
  padding: 10px; }

.p5 {
  padding: 5px; }

.p0 {
  padding: 0 !important; }

.pleft0 {
  padding-left: 0px; }

.pleft8 {
  padding-left: 8px; }

.pleft30 {
  padding-left: 30px; }

.pleft20 {
  padding-left: 20px; }

.pleft40 {
  padding-left: 40px; }

.pleft15 {
  padding-left: 15px; }

.pright0 {
  padding-right: 0px; }

.pright20 {
  padding-right: 20px; }

.pright15 {
  padding-right: 15px; }

.pright40 {
  padding-right: 40px; }

.pbottom10 {
  padding-bottom: 10px; }

.pbottom20 {
  padding-bottom: 20px; }

.ptop40 {
  padding-top: 40px; }

.ptop20 {
  padding-top: 20px; }

.ptop15 {
  padding-top: 15px; }

.ptop10 {
  padding-top: 10px; }

.p40 {
  padding: 40px; }
  @media screen and (max-width: 1169px) {
    .p40 {
      padding: 20px; } }

.m0 {
  margin: 0 !important; }

.mleft0 {
  margin-left: 8px; }

.mleft8 {
  margin-left: 8px; }

.mleft15 {
  margin-left: 15px; }

.mleft16 {
  margin-left: 16px; }

.mleft20 {
  margin-left: 20px; }

.mleft25 {
  margin-left: 25px; }

.mleft32 {
  margin-left: 32px; }

.mleft45 {
  margin-left: 45px; }

.mleft10 {
  margin-left: 10px; }

.mleft10 {
  margin-left: 10px; }

.mLeftAuto {
  margin-left: auto !important; }

.mAuto {
  margin: auto !important; }

.mtop0 {
  margin-top: 0; }

.mtop5 {
  margin-top: 5px; }

.mtop8 {
  margin-top: 8px; }

.mtop10 {
  margin-top: 10px; }

.mtop15 {
  margin-top: 15px; }

.mtop16 {
  margin-top: 16px; }

.mtop20 {
  margin-top: 20px; }

.mtop22 {
  margin-top: 22px; }

.mtop24 {
  margin-top: 24px; }

.mtop30 {
  margin-top: 30px; }

.mtop40 {
  margin-top: 40px !important; }

.mtop50 {
  margin-top: 50px; }

.mtop60 {
  margin-top: 60px; }

.mtop80 {
  margin-top: 80px; }

.mright4 {
  margin-right: 4px; }

.mright8 {
  margin-right: 8px; }

.mright15 {
  margin-right: 15px; }

.mright16 {
  margin-right: 16px !important; }

.mright20 {
  margin-right: 20px; }

.mright30 {
  margin-right: 30px; }

.mright32 {
  margin-right: 32px; }

.mRightAuto {
  margin-right: auto !important; }

.mbottom0 {
  margin-bottom: 0 !important; }

.mbottom5 {
  margin-bottom: 5px; }

.mbottom8 {
  margin-bottom: 8px; }

.mbottom15 {
  margin-bottom: 15px; }

.mbottom16 {
  margin-bottom: 16px; }

.mbottom20 {
  margin-bottom: 20px; }

.mbottom24 {
  margin-bottom: 24px !important; }

.mbottom30 {
  margin-bottom: 30px; }

.mbottom32 {
  margin-bottom: 32px; }

.mbottom40 {
  margin-bottom: 40px; }

.mbottom56 {
  margin-bottom: 56px; }

.mbottom70 {
  margin-bottom: 70px; }

.mbottom80 {
  margin-bottom: 80px; }

.mbottom104 {
  margin-bottom: 104px; }

.mbottom144 {
  margin-bottom: 144px; }

.width30 {
  width: 90px !important; }

.width100 {
  width: 100% !important; }

.fillHeight {
  height: 100%; }

.fullHeight {
  height: 100vh; }

.textCenter {
  text-align: center; }

.textLeft {
  text-align: left; }

.textRight {
  text-align: right; }

.textPrimary {
  color: #3042d4; }

.textGrey {
  color: #6e7581; }

.textDarkGrey {
  color: #919ca7; }

.textBlack {
  color: #000000; }

.textWhite {
  color: white; }

.textYellow {
  color: #edba42; }

.textRed {
  color: #ed543d; }

.textBlue {
  color: #3042d4; }

.textGreen {
  color: #38a181; }

.textlightGrey {
  color: #637381; }

::-webkit-scrollbar {
  display: none; }

.flex1 {
  display: flex;
  flex: 1; }

.flex {
  display: flex; }

.flexEnd {
  display: flex;
  justify-content: flex-end; }

.flexRowCenter {
  display: flex;
  justify-content: center;
  align-items: center; }

.alignCenter {
  display: flex;
  align-items: center; }

.flexStart {
  display: flex;
  align-items: start;
  justify-content: start; }

.flexColumn {
  display: flex;
  flex-direction: column; }

.flexRow {
  display: flex;
  flex-direction: row; }

.flexWrap {
  flex-wrap: wrap; }

.flexContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  margin: -8px; }
  .flexContainer > .flexItem {
    margin: 8px; }

.floatRight {
  float: right; }

.forceCursor {
  cursor: pointer; }

.subHeader {
  display: flex;
  justify-content: space-between; }
  .subHeader h2,
  .subHeader h3 {
    margin-top: 0px; }

.error {
  color: #ff0000 !important; }

.relative {
  position: relative; }

.absolute {
  position: absolute; }

.defaultImageStyle {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; }

.titleModal {
  color: #212b35;
  font-weight: 700;
  margin: 0px; }

.boldLink {
  font-weight: 700;
  text-decoration: none; }

.DraftEditor-root {
  min-height: 150px; }

.cleanList {
  margin: 0;
  padding-left: 1em;
  line-height: 1.5; }

.accountFormContainer {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 16px;
  width: 100%; }
  .accountFormContainer .formIcon {
    margin-bottom: 18px; }
  .accountFormContainer .accountFormHeader {
    font-size: 28px;
    margin-bottom: 20px;
    text-align: center;
    color: #212b35;
    font-weight: 800; }
  .accountFormContainer .accountFormText {
    font-size: 14px;
    line-height: 27px;
    color: #000000;
    font-weight: 300; }
    .accountFormContainer .accountFormText a {
      color: #3042d4;
      font-size: inherit;
      font-weight: inherit;
      text-decoration: none; }
      .accountFormContainer .accountFormText a:hover {
        text-decoration: underline; }
    .accountFormContainer .accountFormText.smaller {
      font-size: 13px; }

@media screen and (min-width: 768px) {
  .accountFormContainer {
    padding: 20px 80px; } }

@media screen and (min-width: 992px) {
  .hidden-md {
    display: none; } }

@media screen and (min-width: 1170px) {
  .accountFormContainer.equalPadding {
    padding: 70px 100px 50px; }
  .accountFormContainer .accountFormHeader {
    font-size: 36px;
    margin-bottom: 40px; }
    .accountFormContainer .accountFormHeader.smallMargin {
      margin-bottom: 20px; } }

@media screen and (max-width: 991px) {
  .hidden-sm {
    display: none; } }

@media screen and (max-width: 767px) {
  .hidden-xs {
    display: none; } }

.divider {
  width: 100%;
  border-bottom: 1px solid #eeeeee; }

.flexBetween {
  display: flex;
  align-items: center;
  justify-content: space-between; }

.selfStart {
  align-self: flex-start; }

.selfCenter {
  align-self: center; }

.selfEnd {
  align-self: flex-end; }

.wAuto {
  width: auto; }

.wFull {
  width: 100%; }

body {
  width: 100%;
  background-color: #ffffff; }

p,
h1,
h2,
h3,
h4,
h5,
h6,
span,
input,
a,
div,
li {
  font-family: 'Poppins' !important; }

h1,
h3,
h4,
h5 {
  font-family: 'Petrona' !important;
  font-weight: 700; }

h2,
p {
  font-weight: 400; }

p,
span,
div {
  font-size: 14px; }

/* Display large */
h1 {
  font-size: 36px;
  line-height: 42px;
  margin: 10px 0px 10px 0px;
  color: #f53920; }

/* Display small */
h2 {
  font-size: 21px; }

/* Heading */
h3 {
  font-size: 17px; }

/* Subheading */
h4 {
  font-size: 14px;
  line-height: 14px; }

/* Caption */
h5 {
  font-size: 12px;
  line-height: 14px; }

/* Body */
p,
li {
  font-size: 17px;
  line-height: 23px; }

.xlarge {
  font-size: 45px;
  line-height: 47px; }

.smallBody {
  font-size: 14px;
  line-height: 20px; }

.bold {
  font-weight: 700; }

.medium {
  font-weight: 500; }

.normal {
  font-weight: 400; }

.light {
  font-weight: 300; }

.btnDefault {
  min-width: 179px !important; }

.btnMedium {
  min-width: 120px !important; }

.inline {
  display: inline-block; }

a {
  cursor: pointer;
  color: #3042d4;
  text-decoration: underline; }

form {
  width: 100%; }

.fontPoppins {
  font-family: 'Poppins' !important; }

.fontPetrona {
  font-family: 'Petrona' !important; }

.font--blue_solid {
  color: #637381; }

.font--blue {
  color: #3042d4 !important; }
