.banner {
  background-color: #faf7f5;
  height: 425px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 20px; }
  .banner .content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    width: 50%;
    height: 350px;
    float: left; }
    .banner .content h1 {
      padding-right: 220px;
      color: #212b35; }
    .banner .content p {
      max-width: 440px; }
    .banner .content a {
      font-weight: 700;
      text-decoration: none; }
    .banner .content .buttonsContainer {
      display: flex;
      margin-top: 35px; }
      .banner .content .buttonsContainer .btn_primary {
        min-width: 150px !important; }
  .banner .imgContainer {
    width: 50%;
    float: left; }
  @media screen and (max-width: 991px) {
    .banner .content h1 {
      padding-right: 0px; }
    .banner .content .buttonsContainer {
      display: flex;
      flex-direction: column; }
      .banner .content .buttonsContainer .btnLearnHow {
        margin-left: 0px;
        margin-top: 10px; }
    .banner .imgContainer {
      padding: 50px 0px; }
      .banner .imgContainer img {
        width: 90%; } }
  @media screen and (max-width: 767px) {
    .banner .content {
      width: 100%; }
      .banner .content .buttonsContainer {
        flex-direction: row;
        align-items: center;
        justify-content: center; }
        .banner .content .buttonsContainer .btnLearnHow {
          margin-left: 10px;
          margin-top: 0px; }
        .banner .content .buttonsContainer .btn_primary {
          min-width: 100px !important; }
    .banner .imgContainer {
      display: none; } }
  .banner.banner-signUp {
    background-color: #3042d4; }
    .banner.banner-signUp h1, .banner.banner-signUp p {
      color: #ffffff !important; }
    .banner.banner-signUp .buttonsContainer {
      margin-top: 35px; }
