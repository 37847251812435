.inviteVolunteerModal {
  padding-top: 100px !important;

  .membersList {
    max-height: 300px;
    overflow-y: scroll;
    padding-top: 15px;
    .memberRow {
      padding: 12px;
      .selectUserImg {
        width: 20px;
        height: 20px;
      }
    }
  }

  .iconButton {
    display: none !important;
  }
}
