.createCharityContainer {
  text-align: center;
  background-color: #f3f5f7;
  padding-bottom: 70px;
  padding-top: 160px; }
  .createCharityContainer .detailsContainer .nameContainer {
    display: flex;
    margin-top: 20px;
    background-color: #ffffff;
    padding: 20px 30px 30px 30px;
    justify-content: center; }
    .createCharityContainer .detailsContainer .nameContainer .leftContainer {
      display: inline-block;
      text-align: center; }
      .createCharityContainer .detailsContainer .nameContainer .leftContainer .instructions {
        padding-left: 15px;
        padding-right: 15px;
        color: #637381; }
    .createCharityContainer .detailsContainer .nameContainer .rightContainer {
      display: inline-block;
      padding-left: 40px;
      text-align: left; }
  .createCharityContainer .detailsContainer .contentContainer {
    text-align: center;
    max-width: 720px;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto; }
  .createCharityContainer .detailsContainer .categoriesContainer {
    margin-top: 60px;
    text-align: left;
    max-width: 720px;
    margin-left: auto;
    margin-right: auto; }
    .createCharityContainer .detailsContainer .categoriesContainer .titleCategoriesContainer p {
      margin: 10px 0px 0px 0px; }
    .createCharityContainer .detailsContainer .categoriesContainer .titleCategoriesContainer h3 {
      font-family: 'Poppins' !important;
      color: #000000;
      font-size: 14px;
      margin-bottom: 15px; }
    .createCharityContainer .detailsContainer .categoriesContainer .titleCategoriesContainer .maxReached {
      color: #3042d4;
      font-weight: 700; }
    .createCharityContainer .detailsContainer .categoriesContainer .areasContainer {
      margin-top: 40px; }
  .createCharityContainer .contactsContainer {
    padding: 15px; }
    .createCharityContainer .contactsContainer .contentContainer {
      margin-top: 40px;
      max-width: 720px;
      margin-left: auto;
      margin-right: auto;
      background-color: #ffffff; }
    .createCharityContainer .contactsContainer .buttonsContainer {
      margin-top: 40px; }
  .createCharityContainer .membersContainer .membersContainerContent {
    text-align: left;
    border-radius: 2px;
    padding: 20px;
    margin-top: 60px;
    background-color: #ffffff; }
    .createCharityContainer .membersContainer .membersContainerContent h3 {
      margin: 0px; }
    .createCharityContainer .membersContainer .membersContainerContent .membersInput {
      max-width: 350px; }
    .createCharityContainer .membersContainer .membersContainerContent .usersContainer {
      margin-top: 40px;
      margin-bottom: 40px; }
      .createCharityContainer .membersContainer .membersContainerContent .usersContainer .user {
        display: flex;
        align-items: center;
        margin-bottom: 10px; }
        .createCharityContainer .membersContainer .membersContainerContent .usersContainer .user .pic {
          width: 46px;
          height: 46px;
          display: inline-block;
          border-radius: 50%;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover; }
        .createCharityContainer .membersContainer .membersContainerContent .usersContainer .user h3 {
          display: inline-block;
          margin-left: 15px; }
        .createCharityContainer .membersContainer .membersContainerContent .usersContainer .user .defaultImage {
          width: 46px;
          height: 46px;
          display: inline-block;
          background-color: #828282;
          border-radius: 50%; }
        .createCharityContainer .membersContainer .membersContainerContent .usersContainer .user .deleteIcon {
          margin-left: 20px; }
  .createCharityContainer .membersContainer .termsContainer {
    text-align: left;
    display: flex;
    align-items: center;
    margin-top: 60px; }
    .createCharityContainer .membersContainer .termsContainer .checkbox {
      display: inline-block !important;
      width: auto !important; }
    .createCharityContainer .membersContainer .termsContainer span {
      display: inline-block; }
  .createCharityContainer .membersContainer .textAgreement {
    color: #637381; }
  .createCharityContainer .membersContainer .buttonsContainer {
    margin-top: 80px; }
  .createCharityContainer .buttonsContainer {
    margin-top: 40px; }
    .createCharityContainer .buttonsContainer .btnDefault {
      margin-left: 15px; }
  .createCharityContainer .reviewContainer hr {
    margin: 50px 0 30px; }
  .createCharityContainer .reviewContainer p,
  .createCharityContainer .reviewContainer span {
    color: black; }
  .createCharityContainer .reviewContainer .contactDetailsRow {
    margin-top: 30px;
    margin-bottom: 20px;
    text-align: left; }
  .createCharityContainer .reviewContainer .companyLogo {
    width: 126px;
    height: 126px;
    float: left;
    border-radius: 50%;
    margin-right: 20px; }
    .createCharityContainer .reviewContainer .companyLogo img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .createCharityContainer .reviewContainer .companyDetailsContainer {
    display: flex;
    flex-flow: row wrap;
    align-items: center; }
  .createCharityContainer .reviewContainer .lineItem {
    display: block;
    margin-bottom: 10px;
    font-weight: 300; }
    .createCharityContainer .reviewContainer .lineItem.smallMargin {
      margin-bottom: 3px; }
  .createCharityContainer .reviewContainer .companyName {
    font-weight: 800;
    font-size: 28px;
    margin-top: 0;
    margin-bottom: 7px; }
  .createCharityContainer .reviewContainer .sectionHeading {
    font-family: 'Poppins' !important;
    font-size: 14px;
    font-weight: 700;
    margin-top: 10px; }
  .createCharityContainer .reviewContainer .contactDetailsColumn {
    word-wrap: break-word;
    border-left: 1px solid #eeeeee;
    padding: 10px 0 10px 40px; }
  .createCharityContainer .reviewContainer .singleCause {
    font-weight: 300;
    color: #3042d4; }
    .createCharityContainer .reviewContainer .singleCause:after {
      content: '|';
      display: inline-block;
      padding: 0 8px; }
    .createCharityContainer .reviewContainer .singleCause:last-child:after {
      content: none; }
  @media screen and (max-width: 767px) {
    .createCharityContainer {
      margin-top: 50px; }
      .createCharityContainer .detailsContainer {
        padding: 15px; }
        .createCharityContainer .detailsContainer .nameContainer .leftContainer {
          display: flex;
          flex-direction: column;
          align-items: center; }
        .createCharityContainer .detailsContainer .nameContainer .rightContainer {
          padding-left: 15px; }
      .createCharityContainer .contactsContainer .buttonsContainer {
        display: flex;
        justify-content: space-around; }
        .createCharityContainer .contactsContainer .buttonsContainer .btnDefault {
          margin-left: 0px;
          min-width: 150px !important; }
      .createCharityContainer .membersContainer {
        padding: 15px; } }
  @media screen and (min-width: 768px) {
    .createCharityContainer .reviewContainer {
      width: 720px;
      margin: 0 auto; }
      .createCharityContainer .reviewContainer .buttonsContainer {
        margin-top: 60px; } }
