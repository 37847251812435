@import 'src/styles/_defines';

.headerContainer {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  border-bottom: 1px solid $blue_light;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  background-color: #fff;
  z-index: 10;
  .topLeftContainer {
    img {
      width: 120px;
    }
    display: flex;
    justify-content: center;
    align-items: center;

    .linksContainer {
      margin-left: 20px;
      a {
        padding: 0px 20px;
        color: $black;
      }
    }
  }
  a {
    text-decoration: none;
  }
  .contentHeader {
    display: flex;
    align-items: center;
    height: 100%;
    .actionsContainer {
      display: flex;
      align-items: center;
      a {
        padding: 0px 7px;
      }
      .createTaskContainer {
        margin-left: 20px;
      }

      .iconButton {
        height: 45px !important;
        width: 45px !important;
        padding: 0 !important;
        margin: 0 8px !important;
      }

      .badgeIcon {
        position: absolute;
        width: 20px;
        height: 20px;
        background: #E9396E;
        border-radius: 25px;
        top: 0px;
        right: 3px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 9px;
        line-height: 14px;
      }
    }
    .userContainer {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .notLoggedUser {
        padding: 0px 20px;
        a {
          color: $primary_body;
          margin-right: 20px;
        }
      }
      .loggedUserMenu {
        padding: 13px 40px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        .contentUserContainer {
          display: flex;
          align-items: center;
          p {
            font-family: 'Poppins' !important;
            font-style: normal;
            font-size: 14px;
            padding: 0px 8px;
          }
          .imgUser {
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            width: 32px;
            height: 32px;
            border-radius: 50%;
          }
          .actionRequired {
            width: 5px;
            height: 5px;
            background-color: $primary_color;
            border-radius: 50%;
            position: absolute;
            right: 20px;
          }
          .arrowIconDown {
            margin-top: 4px;
          }
        }
      }
    }
  }
}

.userPopover {
  border-radius: 16px !important;
  width: 180px;
  .mainMenuUserContent {
    padding: 0px !important;
    .menuCharityItem {
      display: flex;
      align-items: center;
      .imgCharity {
        width: 24px;
        height: 24px;
        min-width: 24px;
        background-size: contain;
        border: 1px solid $grey_lighter;
        border-radius: 50%;
        margin-right: 8px;
      }
    }
    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 180px;
    }
    .hideOnDesktop {
      display: none !important;
    }
  }
}
@media screen and (min-width: $screen-sm-min) {
  [role='presentation'] {
    width: 180px !important;
  }
}
@media screen and (max-width: $screen-xs-max) {
  .headerContainer {
    height: 93px;
    padding: 0px 24px 0px 17px;
    .topLeftContainer {
      img {
        width: 110px;
      }
      .linksContainer {
        display: none;
      }
    }
    .mobileTopRightContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      .mobileIcon {
        width: 30px !important;
        height: 30px !important;
      }
      .contentHeader {
        .userContainer {
          .notLoggedUser {
            padding: 0px 10px;
            .loginButton {
              display: none;
            }
          }
        }
      }
      .createTaskContainer {
        padding: 0px 10px;
      }
    }
  }
  .userPopover {
    width: 100%;
    border-radius: 0px !important;
    margin-top: 93px;
    text-align: center;
    .mainMenuUserContent {
      padding: 0px 0px 20px 0px !important;
      span {
        width: auto;
      }
      .menuCharityItem {
        border-bottom: 1px solid $grey_lighter;
        margin-bottom: 20px;
        justify-content: center;
        span {
          padding: 20px 0px;
        }
        .imgCharity {
          width: 40px;
          height: 40px;
        }
      }
      .hideOnDesktop {
        display: block !important;
      }
    }
  }

  [role='presentation'] {
    width: 100% !important;
  }
}

.myCharitiesContainer {
  width: 263px;
  float: left;
  padding: 25px;
  h5 {
    margin-top: 0px;
    margin-bottom: 20px;
  }
  .charityContainer {
    padding: 10px 0px;
    border-bottom: 1px solid $grey_lighter;
    a {
      text-decoration: none;
      color: $primary_body;
    }
  }
}
