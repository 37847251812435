.ambassadorDetailsContainer .header {
  background-color: #ebebeb;
  text-align: center;
  padding: 50px 50px 30px 50px; }
  .ambassadorDetailsContainer .header .ambassadorPic {
    width: 270px;
    height: 270px;
    border-radius: 50%;
    margin-left: auto;
    margin-right: auto; }
  .ambassadorDetailsContainer .header h1 {
    color: #212b35; }
  .ambassadorDetailsContainer .header .description {
    margin-top: 10px;
    color: #637381; }
  .ambassadorDetailsContainer .header .socialButtons img {
    width: 32px;
    height: 32px; }
    .ambassadorDetailsContainer .header .socialButtons img:hover {
      opacity: 0.8; }
  .ambassadorDetailsContainer .header .socialButtons .twitterIcon {
    margin-left: 10px; }

.ambassadorDetailsContainer .content {
  margin-bottom: 100px; }
  .ambassadorDetailsContainer .content .quotesContainer {
    margin-top: 50px; }
    .ambassadorDetailsContainer .content .quotesContainer .quotes {
      float: right;
      transform: scale(-1);
      -moz-transform: scale(-1);
      -webkit-transform: scale(-1);
      -ms-transform: scale(-1);
      font-size: 80px;
      color: #20c1c9; }
    .ambassadorDetailsContainer .content .quotesContainer .quotesContent {
      text-align: left;
      color: #212b35; }
    .ambassadorDetailsContainer .content .quotesContainer .quotesAuthor {
      margin-top: 10px;
      color: #637381; }
  .ambassadorDetailsContainer .content .charityContainer {
    text-align: center;
    margin-top: 100px; }
    .ambassadorDetailsContainer .content .charityContainer .charityImg {
      width: 164px;
      height: 164px;
      margin-left: auto;
      margin-right: auto;
      border-radius: 50%; }
    .ambassadorDetailsContainer .content .charityContainer h1 {
      margin-top: 20px; }

@media screen and (max-width: 767px) {
  .ambassadorDetailsContainer .backButton {
    display: none; }
  .ambassadorDetailsContainer .defaultPageContainer {
    padding: 15px;
    margin-top: 30px; } }
