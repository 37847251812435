.testimonialContainer {
  height: 440px;
  background-color: #faf7f5;
  display: flex;
  align-items: center; }
  .testimonialContainer .content {
    max-width: 1200px;
    display: flex;
    align-items: center;
    border-radius: 4px; }
    .testimonialContainer .content .imageContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      background-position: 50px;
      background-size: contain;
      background-repeat: no-repeat; }
      .testimonialContainer .content .imageContainer .profileImage {
        width: 337px;
        height: 337px;
        border-radius: 50%; }
    .testimonialContainer .content .quotesContainer .quotes {
      transform: scale(-1);
      -moz-transform: scale(-1);
      -webkit-transform: scale(-1);
      -ms-transform: scale(-1);
      font-size: 70px;
      position: absolute;
      left: -60px;
      color: #20c1c9; }
    .testimonialContainer .content .quotesContainer .quotesContent {
      text-align: left;
      color: #212b35;
      padding-right: 25px; }
    .testimonialContainer .content .quotesContainer .quotesAuthor {
      margin-top: 10px; }
    .testimonialContainer .content .quotesContainer .btnLearnHow {
      min-width: 150px !important;
      margin-top: 20px; }
    .testimonialContainer .content .btnSignUp {
      min-width: 150px !important; }
  @media screen and (max-width: 1169px) {
    .testimonialContainer .content {
      max-width: 100%; }
      .testimonialContainer .content .imageContainer {
        background-position: 10px; } }
  @media screen and (max-width: 991px) {
    .testimonialContainer .content {
      max-width: 100%; }
      .testimonialContainer .content .imageContainer .profileImage {
        width: 250px;
        height: 250px; }
      .testimonialContainer .content .quotesContainer .quotesContent {
        font-size: 30px;
        line-height: 36px; } }
  @media screen and (max-width: 767px) {
    .testimonialContainer .content {
      display: block; }
      .testimonialContainer .content .imageContainer {
        background: none; }
        .testimonialContainer .content .imageContainer .profileImage {
          width: 120px;
          height: 120px; }
      .testimonialContainer .content .quotesContainer .quotes {
        font-size: 40px;
        left: -20px; }
      .testimonialContainer .content .quotesContainer .quotesContent {
        margin-top: 20px;
        font-size: 24px;
        line-height: 30px;
        padding-right: 0px; } }
