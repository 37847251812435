.forVolunteersPage .wrapperContent {
  max-width: 1280px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  margin: auto;
  height: 100%;
  padding: 0px 60px; }

.forVolunteersPage .content h1 {
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 41px;
  color: black; }

.forVolunteersPage .content p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px; }
  .forVolunteersPage .content p.small {
    font-size: 14px !important;
    line-height: 21px !important; }

.forVolunteersPage .content .line {
  width: 46px;
  height: 2px;
  background-color: white;
  margin-bottom: 44px; }

.forVolunteersPage .introSection {
  width: 100%;
  height: 1200px;
  background-color: #f1efeb;
  overflow: auto;
  margin-top: 60px; }
  .forVolunteersPage .introSection .content {
    width: 100%;
    max-width: 1132px;
    height: 1004px;
    margin: auto;
    background-image: url("../assets/illustration-6.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
    .forVolunteersPage .introSection .content h1 {
      font-weight: 800;
      font-size: 60px;
      line-height: 68px; }
    .forVolunteersPage .introSection .content > div:nth-child(1) {
      max-width: 410px;
      margin: 28px -12px 0 auto; }
    .forVolunteersPage .introSection .content > div:nth-child(2) h1 {
      max-width: 600px;
      margin-bottom: 38px; }
    .forVolunteersPage .introSection .content > div:nth-child(2) p {
      max-width: 456px; }

.forVolunteersPage .createProfileSection {
  height: 708px;
  background: linear-gradient(90deg, #ecb942 50%, #1a3465 50%); }
  .forVolunteersPage .createProfileSection .background {
    width: 50%;
    height: 100%;
    background-image: url("../assets/illustration-7.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center; }
  .forVolunteersPage .createProfileSection .content {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 60px; }
    .forVolunteersPage .createProfileSection .content > div {
      width: 400px; }
      .forVolunteersPage .createProfileSection .content > div h1,
      .forVolunteersPage .createProfileSection .content > div p {
        color: white; }

.forVolunteersPage .howItWorksSection {
  height: 730px;
  background-color: #f1efeb;
  display: flex;
  align-items: center;
  justify-content: center; }
  .forVolunteersPage .howItWorksSection .content {
    text-align: center; }
    .forVolunteersPage .howItWorksSection .content > p {
      font-size: 14px;
      line-height: 21px; }
    .forVolunteersPage .howItWorksSection .content > h1 {
      margin-bottom: 78px;
      font-size: 60px;
      line-height: 68px; }
    .forVolunteersPage .howItWorksSection .content .contentHowItWorks {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      gap: 78px;
      margin-bottom: 56px; }
      .forVolunteersPage .howItWorksSection .content .contentHowItWorks > div {
        max-width: 294px; }
        .forVolunteersPage .howItWorksSection .content .contentHowItWorks > div h2 {
          font-weight: 400;
          font-size: 20px;
          line-height: 30px;
          white-space: break-spaces; }
        .forVolunteersPage .howItWorksSection .content .contentHowItWorks > div p {
          font-weight: 300;
          font-size: 12px;
          line-height: 18px; }
    .forVolunteersPage .howItWorksSection .content .register-btn {
      margin-bottom: 0; }

.forVolunteersPage .verifiedRecordSection {
  height: 816px;
  background-color: #9c3875; }
  .forVolunteersPage .verifiedRecordSection .content {
    width: 40%; }
    .forVolunteersPage .verifiedRecordSection .content > div {
      max-width: 400px;
      margin: 0 auto;
      margin-right: 60px; }
      .forVolunteersPage .verifiedRecordSection .content > div h1,
      .forVolunteersPage .verifiedRecordSection .content > div p {
        color: white; }
      .forVolunteersPage .verifiedRecordSection .content > div .point {
        display: flex;
        align-items: center;
        margin-bottom: 12px; }
        .forVolunteersPage .verifiedRecordSection .content > div .point p {
          margin: 0 0 0 22px; }
  .forVolunteersPage .verifiedRecordSection .img-container {
    width: 60%;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center; }
    .forVolunteersPage .verifiedRecordSection .img-container > img {
      width: 100%; }

.forVolunteersPage .searchCharitiesSection {
  height: 708px;
  background-color: #f1efeb; }
  .forVolunteersPage .searchCharitiesSection .img-container {
    width: 60%;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center; }
    .forVolunteersPage .searchCharitiesSection .img-container img {
      width: 100%; }
  .forVolunteersPage .searchCharitiesSection .content {
    width: 40%;
    max-width: 400px;
    flex-shrink: 0;
    margin-left: 60px; }
    .forVolunteersPage .searchCharitiesSection .content h1 {
      margin-bottom: 50px; }
    .forVolunteersPage .searchCharitiesSection .content p {
      margin-bottom: 42px; }
