.volunteerConfirmationContainer .contentVolunteers {
  background-color: #f1f3f6;
  padding: 120px 0px; }
  .volunteerConfirmationContainer .contentVolunteers .contentInternal {
    text-align: center;
    max-width: 400px; }
    .volunteerConfirmationContainer .contentVolunteers .contentInternal p {
      color: #637381; }
    .volunteerConfirmationContainer .contentVolunteers .contentInternal h2 {
      margin-top: 40px; }
    .volunteerConfirmationContainer .contentVolunteers .contentInternal .btnEventDetails {
      min-width: 300px !important; }

.volunteerConfirmationContainer .eventsForCharity {
  background-color: #ffffff;
  margin-top: 200px; }
