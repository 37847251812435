.assignVolunteerModal {
  .row {
    padding: 8px 0;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #eeeeee;
  }

  .volunteerProfile {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    object-fit: cover;
    border: 1px solid #e3e5e5;
    margin-right: 8px;
  }

  .iconButton {
    display: none !important;
  }

  .message {
    cursor: pointer;
  }

  a {
    color: black;
    text-decoration: none;

    &:hover {
      color: black;
    }
  }
}
