@import 'src/styles/_defines';

.title {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
}

.date {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #6e7581;
}

.volunteerName {
  font-size: 14px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
}

.volunteerProfilePicture {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  margin-right: 15px;
}

.message {
  width: 36px;
  height: 36px;
  margin-right: 8px;
  cursor: pointer;
}
