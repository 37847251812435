@import 'src/styles/_defines';

$sidebarWidth: 310px;

.messagesInbox {
  height: calc(100vh - 60px);
  max-height: calc(100vh - 60px);
  overflow: hidden;
  display: flex;
  .sidebar {
    width: $sidebarWidth;
    border-right: 1px solid $blue_light;
    overflow-y: scroll;
    height: 100%;
    background-color: $grey_bg_2;
    padding-bottom: 100px;
    hr {
      border-top: 1px solid #B5BDC4;
      margin: 15px;
    }
    .sidebarSectionContainer {
      margin-top: 50px;
      .sidebarHeadingSectionContainer {
        display: flex;
        align-items: center;
        padding: 0px 17px;
        a {
          margin-left: 20px;
          &:hover {
            opacity: 0.8;
          }
        }
        svg {
          cursor: pointer;
        }
      }
      h2 {
        font-size: 14px;
        font-weight: 700;
        margin: 0;
        padding: 10px 5px;
        img {
          padding-right: 9px;
        }
      }
      .contentSidebarContainer {
        display: flex;
        flex-direction: column;
        .sidebarSelectableRow {
          display: flex;
          justify-content: space-between;
          font-size: 14px;
          height: 40px;
          padding-left: 46px;
          &:hover {
            .rowMenu {
              visibility: visible;
            }
          }
          &.selected {
            background-color: #c1c7ff;
          }
          .rowBody {
            display: flex;
            align-items: center;
            .profilePic {
              display: flex;
              flex-direction: row;
              margin-right: 7px;
              &.multi {
                img {
                  width: 21px;
                  height: 21px;
                }
                span {
                  width: 22px;
                  height: 22px;
                  margin-top: 4px;
                  margin-left: -14px;
                  border-radius: 50%;
                  background-color: #3042D4;
                  color: white;
                  text-align: center;
                }
              }
              img {
                width: 28px;
                height: 28px;
                border-radius: 50%;
              }
            }
            a {
              text-decoration: none;
              color: $primary_body;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              max-width: 140px;
              margin-right: 6px;
              &.selected {
                font-weight: 600;
              }
              &.forum {
                max-width: 210px;
              }
            }
            .unseenMessageBadge {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 22px;
              height: 22px;
              background-color: #E5153F;
              color: white;
              border-radius: 50%;
            }
          }
          .rowMenu {
            visibility: hidden;
          }
        }
        .showMoreChannels {
          padding: 0 25px 20px 46px;
          color: #7b8af9;
          text-decoration: none;
        }
        .charityFollowingContainer {
          display: flex;
          flex-direction: column;
          &.child-0 {
            .charityRow {
              padding: 10px 5px 5px 17px;
            }
          }
          .charityRow {
            display: flex;
            align-items: center;
            padding: 20px 5px 5px 17px;
            h2 {
              margin-left: 4px;
            }
            img {
              width: 30px;
              height: 30px;
              border-radius: 15px;
            }
          }
        }
        .newForum {
          display: flex;
          align-items: center;
          padding-left: 46px;
          cursor: pointer;
          img {
            margin-right: 7px;
          }
        }
      }
    }
  }
  .messagesContainer {
    width: calc(100vw - 510px);
  }
}
