.modalPaper {
  border-radius: 24px !important;
  box-shadow: none !important;
  overflow: hidden;
}

.modalBody {
  padding: 40px !important;
  position: relative;

  h1 {
    font-size: 32px;
  }

  .modalClose {
    position: absolute;
    top: 24px;
    right: 24px;
  }
}
