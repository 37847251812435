@import 'src/styles/_defines';

.charityDetailsTasks {
  background: #ffffff;
  border-radius: 10px;
  padding-left: 45px;
  padding-right: 45px;
  padding-top: 40px;
  padding-bottom: 40px;
  margin-bottom: 36px;

  .charityTask {
    padding: 24px 40px;
    border-radius: 16px;
    border: 1px solid #e3e5e5;
    width: 100%;
    cursor: pointer;
  }

  .taskClosed {
    .textPrimary {
      color: #6e7581;
    }

    .skillBadge {
      background-color: $blue_solid_light !important;
      color: white !important;
    }
  }
}
