.searchTasks {
  padding-top: 60px;

  .header {
    padding: 0 175px;
    background: #d3ddf3;
    width: 100%;

    .headerContent {
      max-width: 1200px;
      padding: 65px 0 48px 0;
      margin: 0 auto;

      > h1 {
        font-style: normal;
        font-weight: 800;
        font-size: 36px;
        line-height: 41px;
        color: black;
        margin-top: 0;
        margin-bottom: 32px;
      }

      .inputContainer {
        display: flex;
        align-items: center;
        gap: 16px;
        margin-bottom: 40px;

        > input {
          flex: 1;
          margin-bottom: 0;
          padding: 13px;
        }

        > select {
          all: unset;
          flex: 1;
          padding: 13px;
          border: 1px solid #e3e5e6;
          border-radius: 8px;
          outline: 0;
          background-color: white;
        }
      }

      .causesContainer {
        width: 100%;
        margin: 0 auto;

        .buttonsContainer {
          display: flex;
          align-items: center;
          margin-bottom: 24px;

          .selectCausesBtn {
            all: unset;
            cursor: pointer;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 116%;
            color: #000000;

            &::after {
              content: '';
              display: inline-block;
              margin-left: 4px;
              width: 14px;
              height: 10px;
              background-repeat: no-repeat;
              background-image: url("data:image/svg+xml,%3Csvg width='10' height='8' viewBox='0 0 10 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.22229 0.962171C4.62253 0.466996 5.37747 0.466996 5.77771 0.962171L9.74578 5.87138C10.2744 6.52532 9.80892 7.5 8.96807 7.5L1.03193 7.5C0.191083 7.5 -0.274355 6.52532 0.254219 5.87138L4.22229 0.962171Z' fill='black'/%3E%3C/svg%3E");
              background-position: center;
              transition: 0.3s ease;
            }

            &.show {
              &::after {
                transform: rotate(180deg);
              }
            }
          }

          .clearBtn {
            all: unset;
            cursor: pointer;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 116%;
            text-decoration-line: underline;
            color: #000000;
            margin-left: 20px;
          }
        }
      }
    }
  }

  .tasksContainer {
    padding: 80px 0;
    display: flex;
    gap: 40px;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;

    .filterContainer {
      width: 360px;

      .head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 28px;
        padding-top: 12px;

        > h1 {
          font-family: 'Poppins' !important;
          font-style: normal;
          font-weight: 600;
          font-size: 21px;
          line-height: 116%;
          color: #000000;
        }

        .resetBtn {
          all: unset;
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 116%;
          text-align: right;
          text-decoration-line: underline;
          color: #3042d4;
          cursor: pointer;
        }
      }

      .filter {
        padding: 24px;
        border: 1px solid #e3e5e5;
        border-radius: 16px;

        > div {
          margin-bottom: 35px;

          > h1 {
            font-family: 'Poppins' !important;
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 113%;
            color: #000000;
            margin: 0 0 22px 0;
          }

          > div {
            margin-bottom: 10px;

            label {
              margin-left: -6px;
              font-family: 'Poppins' !important;
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              color: #6e7581 !important;
            }
          }
        }
      }
    }

    .tasks {
      flex: 1;

      > div {
        padding: 22px 32px 8px 18px;
        border-radius: 10px;

        > h1 {
          font-family: 'Poppins' !important;
          font-style: normal;
          font-weight: 600;
          font-size: 21px;
          line-height: 116% !important;
          color: #000000;
          margin: 0 0 38px 0;
        }
      }

      .myNetwork {
        background-color: #f2f2f2;
        margin-bottom: 56px;

        > h1 {
          margin-bottom: 24px;
        }
      }
    }
  }
}
