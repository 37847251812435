.defaultTable .tableHeader {
  background-color: #e5e5e5;
  border-bottom: 1px solid #eeeeee !important; }
  .defaultTable .tableHeader th {
    font-size: 14px !important;
    font-weight: 300 !important;
    letter-spacing: 0.6px !important;
    color: black !important;
    background-color: white !important;
    font-family: 'Poppins' !important; }

.defaultTable .contentRow {
  padding-bottom: 10px;
  padding-top: 10px;
  height: 70px !important;
  height: 112px !important;
  border-bottom: 1px solid #eeeeee !important; }
  .defaultTable .contentRow td {
    background-color: white !important; }
  .defaultTable .contentRow .narrow a {
    font-size: 14px;
    font-weight: 400;
    color: black;
    text-decoration: none; }
  .defaultTable .contentRow .narrow span {
    font-size: 14px;
    font-weight: 400; }

.defaultTable .actionGroupContainer {
  width: 180px;
  background-color: #3042d4;
  color: white;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  border-radius: 8px;
  cursor: pointer;
  padding: 10px 0px; }
  .defaultTable .actionGroupContainer .actionGroupHeader {
    padding: 0px 14px;
    font-weight: 500;
    cursor: default; }
  .defaultTable .actionGroupContainer .action {
    margin-top: 5px;
    padding: 0px 14px;
    margin-bottom: 5px; }
  .defaultTable .actionGroupContainer :hover {
    cursor: pointer;
    text-decoration: underline; }

.defaultTable .groupTitle span,
.defaultTable .groupTitle a > span {
  font-size: 14px !important;
  font-weight: 400 !important; }

.defaultTable .groupTitle :hover {
  text-decoration: underline;
  cursor: pointer; }

.defaultTable .upcoming {
  color: #ed543d;
  font-size: 14px !important;
  font-weight: 300 !important; }

.defaultTable .in-progress {
  color: #edba42;
  font-size: 14px !important;
  font-weight: 300 !important; }

.defaultTable .awaiting-review {
  color: #38a181;
  font-size: 14px !important;
  font-weight: 300 !important; }

.defaultTable .completed {
  color: #919ca7;
  font-size: 14px !important;
  font-weight: 300 !important; }

.defaultTable .not-assigned {
  color: #919ca7;
  font-size: 14px !important;
  font-weight: 300 !important; }
