@import 'src/styles/_defines';
@import 'src/styles/_layout';

.guideContainer {
  border: 1px solid $blue_solid_light;
  border-radius: 20px;
  padding-top: 32px;
  padding-bottom: 32px;
  padding-left: 38px;
  padding-right: 38px;
  cursor: pointer;

  &:hover {
    border: 2px solid $primary_color;
  }

  .title {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 700;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
  }

  .content {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: $secondary_body;
  }

  img:first-child {
    width: 50px;
    height: 44px;
  }
}
