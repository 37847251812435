.applyForEventContainer .contentApply {
  padding: 120px 0px;
  background-color: #f1f3f6; }

.applyForEventContainer .contentInternal {
  text-align: center;
  max-width: 600px; }
  .applyForEventContainer .contentInternal p {
    color: #637381; }
  .applyForEventContainer .contentInternal h2 {
    margin-top: 40px; }
  .applyForEventContainer .contentInternal .skillsetContainer {
    max-width: 410px;
    margin-left: auto;
    margin-right: auto; }
    .applyForEventContainer .contentInternal .skillsetContainer a {
      font-size: 21px; }
    .applyForEventContainer .contentInternal .skillsetContainer .wrapContainer {
      justify-content: center; }
  .applyForEventContainer .contentInternal .btnSendApplicantion {
    min-width: 300px !important; }

@media screen and (max-width: 767px) {
  .applyForEventContainer .contentApply {
    padding: 20px 40px 40px 40px; } }
