@import 'src/styles/_defines';

.taskCard {
  padding: 32px;
  border: 1px solid #e3e5e5;
  border-radius: 16px;
  background-color: white;
  margin-bottom: 22px;

  .taskHeader {
    display: flex;
    align-items: flex-start;
    margin-bottom: 30px;

    .logo {
      width: 115px;
      margin-right: 42px;
      flex-shrink: 0;
      text-align: center;

      > img {
        width: 85px;
        height: 85px;
        border-radius: 50%;
        margin-bottom: 12px;
      }

      > p {
        font-family: 'Poppins' !important;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 113%;
        text-align: center;
        color: #6e7581;
        margin: 0;
      }
    }

    .info {
      flex: 1;

      .name {
        display: flex;
        align-self: center;
        justify-content: space-between;
        margin-bottom: 16px;

        > h1 {
          font-family: 'Poppins' !important;
          font-style: normal;
          font-weight: 600 !important;
          font-size: 20px;
          line-height: 113%;
          color: #000000;
          margin: 0;
        }

        > p {
          margin: 0;
          font-family: 'Poppins' !important;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 130%;
          color: #6e7581;
        }
      }

      .skillInfo {
        display: flex;
        align-items: center;
        gap: 12px;
        margin-bottom: 22px;

        .skill {
          background: $blue_dark;
          border-radius: 5px;
          font-family: 'Poppins' !important;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          color: #ffffff;
          padding: 6px 18px;
        }

        .subSkill {
          font-family: 'Poppins' !important;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 113%;
          color: #212b35;
          padding: 6px 18px;
          border: 1px solid #212b35;
          border-radius: 100px;
        }
      }
    }
  }

  .description {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 130%;
    color: #6e7581;
    margin: 0;
  }

  .connection {
    display: flex;
    align-items: center;
    margin-top: 24px;

    > p {
      font-family: 'Poppins' !important;
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 130%;
      color: #6e7581;
      margin: 0 8px 0 0;
    }

    .user {
      display: flex;
      align-items: center;

      img {
        margin-right: 8px;
        height: 22px;
        width: 22px;
        border-radius: 50%;
      }

      p {
        font-family: 'Poppins' !important;
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 130%;
        color: #3042d4;
        margin: 0;
      }
    }
  }
}
