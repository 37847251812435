.legalContainerNew {
  padding-top: 60px; }
  .legalContainerNew h1 {
    font-family: 'Petrona', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 26px;
    line-height: 24px;
    margin-top: 0;
    margin-bottom: 24px;
    color: black;
    scroll-margin-top: 125px; }
  .legalContainerNew h2 {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    margin-top: 0;
    margin-bottom: 24px;
    color: black; }
  .legalContainerNew p,
  .legalContainerNew span,
  .legalContainerNew a,
  .legalContainerNew li {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-top: 0;
    margin-bottom: 24px;
    color: #6e7581; }
  .legalContainerNew a {
    text-decoration-line: underline;
    color: black; }
    .legalContainerNew a:hover {
      color: black; }
  .legalContainerNew ul {
    margin-bottom: 24px;
    padding-left: 26px; }
    .legalContainerNew ul li {
      margin-bottom: 0; }
  .legalContainerNew .bold {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 24px;
    font-weight: 700; }
  .legalContainerNew .header {
    width: 100%;
    height: 216px;
    background: #1a3465;
    overflow: hidden; }
    .legalContainerNew .header h1 {
      font-size: 60px;
      line-height: 68px;
      color: #ffffff;
      margin-left: 144px;
      margin-top: 104px; }
  .legalContainerNew .mainContainer {
    display: flex; }
    .legalContainerNew .mainContainer .content {
      padding: 80px 144px;
      min-width: 1000px;
      width: 70%; }
    .legalContainerNew .mainContainer .sidebar {
      width: 30%;
      padding: 80px 0 0 0;
      display: flex;
      flex-direction: column; }
  .legalContainerNew .underline {
    text-decoration: underline; }
