.membersList {
  img {
    height: 36px;
    width: 36px;
    border-radius: 18px;
    object-fit: cover;
  }

  &.scrollable {
    max-height: 360px;
    overflow-y: scroll;
  }

  .memberRow {
    padding: 15px 32px;
    cursor: pointer;
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    text-decoration-line: none !important;
    text-decoration: none !important;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:hover {
      background-color: rgba(153, 153, 153, 0.2);
    }

    &.active {
      background-color: #3042d4;
      color: white;
    }

    .userInfoContainer {
      gap: 5px;
      flex: 1
    }

    .nameMacroContainer {
      align-items: center;
      gap: 25px;

      a {
        color: black;
        text-decoration: none;

        &:hover {
          color: black;
          text-decoration: underline;
        }
      }
    }

  }
}
