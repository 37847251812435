@import "src/styles/_defines";

.appRoot {
    .appContent {
        width: 100%;
        padding: 0px;
        &.noHeader {
            margin-top: 0px;
        }
    }
}