.aboutUsContainer .section1 img, .aboutUsContainer .section2 img, .aboutUsContainer .section3 img {
  max-width: 60%; }

.aboutUsContainer .section1 {
  height: 700px;
  display: flex;
  align-items: center;
  justify-content: space-around; }
  .aboutUsContainer .section1 .content {
    max-width: 355px;
    margin-top: 40px; }

.aboutUsContainer .section2 {
  height: 600px;
  display: flex;
  align-items: center;
  justify-content: space-around; }
  .aboutUsContainer .section2 .content {
    max-width: 445px; }

.aboutUsContainer .section3 {
  margin-top: 50px; }
  .aboutUsContainer .section3 .contents {
    display: flex;
    align-items: baseline;
    justify-content: space-around; }
    .aboutUsContainer .section3 .contents .littleBlock {
      max-width: 225px; }
      .aboutUsContainer .section3 .contents .littleBlock.benefit1 img {
        height: 21px; }
      .aboutUsContainer .section3 .contents .littleBlock.benefit2 img {
        height: 27px; }
      .aboutUsContainer .section3 .contents .littleBlock.benefit3 img {
        height: 27px; }
  .aboutUsContainer .section3 .buttonsContainer {
    margin-top: 40px;
    text-align: center; }
    .aboutUsContainer .section3 .buttonsContainer .btnOpportunities {
      min-width: 280px !important; }

.aboutUsContainer .testimonial {
  margin-top: 100px;
  padding-bottom: 50px; }

.aboutUsContainer .joinContainer {
  background-color: #3042d4;
  text-align: center; }
  .aboutUsContainer .joinContainer .internalContainerStyle {
    padding: 120px 200px; }
  .aboutUsContainer .joinContainer .title {
    color: #ffffff;
    margin-top: 20px; }
  .aboutUsContainer .joinContainer p {
    color: #ffffff; }

.aboutUsContainer .btnDefaultAboutUs {
  min-width: 180px !important; }

@media screen and (max-width: 1169px) {
  .aboutUsContainer .section1, .aboutUsContainer .section2, .aboutUsContainer .section3 {
    padding: 0px 40px !important; }
  .aboutUsContainer .section1 {
    height: 600px; }
  .aboutUsContainer .section2 {
    height: 500px; }
    .aboutUsContainer .section2 img {
      margin-right: 25px; }
  .aboutUsContainer .section3 {
    margin-top: 100px; } }

@media screen and (max-width: 991px) {
  .aboutUsContainer .section1 {
    height: 500px; }
    .aboutUsContainer .section1 .content .title {
      font-size: 36px;
      line-height: 42px; }
  .aboutUsContainer .joinContainer .internalContainerStyle {
    padding: 120px; } }

@media screen and (max-width: 767px) {
  .aboutUsContainer .section1, .aboutUsContainer .section2 {
    height: 350px; }
    .aboutUsContainer .section1 img, .aboutUsContainer .section2 img {
      display: none; }
  .aboutUsContainer .section3 {
    margin-top: 20px; }
    .aboutUsContainer .section3 .contents {
      flex-direction: column; }
      .aboutUsContainer .section3 .contents .littleBlock {
        max-width: 100%;
        padding: 0px; }
  .aboutUsContainer .joinContainer .internalContainerStyle {
    padding: 40px; }
  .aboutUsContainer .btnDefaultAboutUs {
    margin-left: 0px !important; } }
