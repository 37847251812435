.personThumbnailContainer {
  width: 155px;
  max-height: 155px;
  text-align: center;
  text-decoration: none;
  float: left;
  border-right: 1px solid #e4eaf7;
  padding: 0px 15px 0px 15px;
  margin: 15px 0px; }
  .personThumbnailContainer .profilePicture {
    width: 85px;
    height: 85px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 50%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat; }
  .personThumbnailContainer h3 {
    margin-bottom: 5px;
    color: #212b35; }
  .personThumbnailContainer p {
    margin: 0px;
    color: #637381; }
  @media screen and (min-width: 1170px) {
    .personThumbnailContainer:nth-child(5) {
      clear: both; } }
  @media screen and (max-width: 1169px) {
    .personThumbnailContainer:nth-child(4) {
      clear: both; } }
  @media screen and (max-width: 991px) {
    .personThumbnailContainer {
      width: 135px;
      max-height: 135px; }
      .personThumbnailContainer .profilePicture {
        width: 65px;
        height: 65px; } }
  @media screen and (max-width: 767px) {
    .personThumbnailContainer {
      width: 50%; }
      .personThumbnailContainer .profilePicture {
        width: 85px;
        height: 85px; } }
