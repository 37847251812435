.leaveModal {
  .modalBody {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  p {
    line-height: 32px;
  }
}