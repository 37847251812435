.certification {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
  min-height: calc(100vh - 256px);
  height: calc(100vh - 261px);
}
.main {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: calc(100vh - 316px);
  margin-top: 60px;
}
.toolbar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 24px;
  padding: 10px;
}
a {
  text-decoration: none;
}