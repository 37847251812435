.createEventContainer {
  text-align: center;
  background-color: #f1f3f6;
  padding-bottom: 70px; }
  .createEventContainer .defaultPageContainer {
    max-width: 800px; }
  .createEventContainer .eventStepper {
    margin-top: 40px; }
  .createEventContainer .childContainer {
    margin-top: 60px; }
    .createEventContainer .childContainer .helper {
      display: flex;
      flex-direction: row;
      justify-content: space-between; }
      .createEventContainer .childContainer .helper .count {
        color: #B5BDC4; }
  .createEventContainer .detailsContainer .nameContainer {
    text-align: left;
    padding: 10px 50px 50px 50px;
    background-color: #ffffff; }
  .createEventContainer .detailsContainer .datesContainer h5 {
    margin-bottom: 5px;
    margin-top: 25px; }
  .createEventContainer .detailsContainer .datesContainer .fromContainerContent, .createEventContainer .detailsContainer .datesContainer .toContainerContent {
    display: flex;
    align-items: center; }
    .createEventContainer .detailsContainer .datesContainer .fromContainerContent h5, .createEventContainer .detailsContainer .datesContainer .toContainerContent h5 {
      display: inline-block;
      margin: 0px 15px 0px 15px; }
  .createEventContainer .volunteersContainer {
    max-width: 605px;
    margin-left: auto;
    margin-right: auto;
    text-align: left; }
    .createEventContainer .volunteersContainer .radioTitle {
      margin-bottom: 20px; }
      .createEventContainer .volunteersContainer .radioTitle p {
        margin: 0px;
        color: #637381; }
      .createEventContainer .volunteersContainer .radioTitle .title {
        color: #212b35; }
    .createEventContainer .volunteersContainer .numberOfVolunteersContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 40px; }
    .createEventContainer .volunteersContainer .specificSkillsContainer {
      margin-top: 40px;
      border: 1px solid #ebebeb;
      border-radius: 2px;
      padding: 10px 20px 10px 20px;
      background-color: #ffffff; }
      .createEventContainer .volunteersContainer .specificSkillsContainer .specificSkills {
        display: block;
        margin-bottom: 20px; }
        .createEventContainer .volunteersContainer .specificSkillsContainer .specificSkills .skillsInput {
          display: inline-block;
          width: 70%; }
        .createEventContainer .volunteersContainer .specificSkillsContainer .specificSkills .addSkill {
          display: inline-block; }
  .createEventContainer .descriptionContainer {
    max-width: 730px;
    margin-left: auto;
    margin-right: auto; }
    .createEventContainer .descriptionContainer .picturesContainer img {
      border-radius: 0px !important; }
    .createEventContainer .descriptionContainer .picturesContainer .dropzone {
      border-radius: 0px !important;
      width: 100% !important;
      height: 337px !important;
      background-color: #ffffff !important; }
      .createEventContainer .descriptionContainer .picturesContainer .dropzone h4 {
        font-size: 17px;
        line-height: 23px; }
    .createEventContainer .descriptionContainer .aboutEventContainer {
      text-align: left;
      background-color: #ffffff;
      margin-top: 25px;
      padding: 0px 25px 10px 25px; }
    .createEventContainer .descriptionContainer .causesContainer {
      text-align: left;
      margin-top: 40px; }
      .createEventContainer .descriptionContainer .causesContainer .areasContainer {
        padding: 0px 15px 0px 15px; }
    .createEventContainer .descriptionContainer .testimonialsContainer {
      text-align: left;
      margin-top: 25px; }
      .createEventContainer .descriptionContainer .testimonialsContainer .testimonialsContentContainer {
        padding: 0px 15px 0px 15px;
        display: flex;
        align-items: flex-start; }
        .createEventContainer .descriptionContainer .testimonialsContainer .testimonialsContentContainer .leftContainer {
          width: 66%;
          padding-right: 30px; }
          .createEventContainer .descriptionContainer .testimonialsContainer .testimonialsContentContainer .leftContainer .subtitle {
            margin-top: 0px;
            width: 60%; }
          .createEventContainer .descriptionContainer .testimonialsContainer .testimonialsContentContainer .leftContainer .testimonialsCreateContainer {
            margin-bottom: 30px; }
          .createEventContainer .descriptionContainer .testimonialsContainer .testimonialsContentContainer .leftContainer .addAnotherContainer a {
            text-decoration: none;
            display: flex;
            align-items: center; }
          .createEventContainer .descriptionContainer .testimonialsContainer .testimonialsContentContainer .leftContainer .addAnotherContainer .add {
            margin: 12px 12px 12px 0px; }
        .createEventContainer .descriptionContainer .testimonialsContainer .testimonialsContentContainer .rightContainer {
          width: 33%; }
          .createEventContainer .descriptionContainer .testimonialsContainer .testimonialsContentContainer .rightContainer h3 {
            margin-top: 0px;
            margin-bottom: 10px; }
          .createEventContainer .descriptionContainer .testimonialsContainer .testimonialsContentContainer .rightContainer p {
            margin-top: 0px; }
  .createEventContainer .faqsEventContainer {
    text-align: left; }
    .createEventContainer .faqsEventContainer .faqsContainerContent {
      background-color: #ffffff;
      padding: 0px 10px; }
      .createEventContainer .faqsEventContainer .faqsContainerContent .faqsContentContainer {
        padding-bottom: 20px;
        padding-top: 20px;
        border-bottom: 2px dashed #20c1c9; }
        .createEventContainer .faqsEventContainer .faqsContainerContent .faqsContentContainer:last-child {
          border-bottom: none; }
      .createEventContainer .faqsEventContainer .faqsContainerContent .opened {
        opacity: 0; }
      .createEventContainer .faqsEventContainer .faqsContainerContent .faqsCreateContainer .inputComponent {
        margin-left: 20px;
        width: 80%; }
      .createEventContainer .faqsEventContainer .faqsContainerContent .delete {
        display: flex;
        justify-content: flex-end;
        margin: 10px; }
        .createEventContainer .faqsEventContainer .faqsContainerContent .delete .deleteIcon path {
          fill: #b5bdc4; }
        .createEventContainer .faqsEventContainer .faqsContainerContent .delete .deleteIcon:hover path {
          fill: #20c1c9; }
    .createEventContainer .faqsEventContainer .addMoreContainer {
      height: 55px;
      border: 1px solid #20c1c9;
      background-color: #ffffff;
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      font-weight: 700; }
      .createEventContainer .faqsEventContainer .addMoreContainer span {
        margin-left: 5px;
        font-weight: 700; }
    .createEventContainer .faqsEventContainer .helpText {
      margin-bottom: 0px; }
  @media screen and (max-width: 767px) {
    .createEventContainer {
      margin-top: 40px; }
      .createEventContainer .childContainer {
        margin-top: 20px; }
      .createEventContainer .detailsContainer .nameContainer {
        padding: 30px; }
        .createEventContainer .detailsContainer .nameContainer .datesContainer .fromContainer, .createEventContainer .detailsContainer .nameContainer .datesContainer .toContainer {
          padding: 0px; }
      .createEventContainer .volunteersContainer .contentContainer {
        padding: 0px 30px; }
      .createEventContainer .descriptionContainer .contentContainer {
        padding: 30px; }
      .createEventContainer .descriptionContainer .picturesContainer {
        margin-left: 0px; }
        .createEventContainer .descriptionContainer .picturesContainer .dropzone {
          width: 100% !important; }
      .createEventContainer .descriptionContainer .testimonialsContainer .testimonialsContentContainer {
        display: inline-block; }
        .createEventContainer .descriptionContainer .testimonialsContainer .testimonialsContentContainer .leftContainer {
          width: 100%;
          padding-right: 0px; }
          .createEventContainer .descriptionContainer .testimonialsContainer .testimonialsContentContainer .leftContainer .subtitle {
            width: 100%; }
        .createEventContainer .descriptionContainer .testimonialsContainer .testimonialsContentContainer .rightContainer {
          width: 100%;
          margin-top: 40px; }
      .createEventContainer .faqsContainer .contentContainer {
        padding: 30px; } }
