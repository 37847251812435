.homepageContainerNew .content h1 {
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 41px;
  color: black; }

.homepageContainerNew .content p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px; }
  .homepageContainerNew .content p.small {
    font-size: 16px;
    line-height: 21px; }

.homepageContainerNew .wrapperContent {
  max-width: 1280px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  margin: auto;
  height: 100%;
  padding: 0px 60px; }

.homepageContainerNew .introSection {
  width: 100%;
  height: calc(763px + 60px);
  margin-top: 60px;
  background-color: #f1efeb; }
  .homepageContainerNew .introSection .content {
    width: 45%;
    margin-right: 60px; }
    .homepageContainerNew .introSection .content h1 {
      font-size: 60px;
      line-height: 68px; }
    .homepageContainerNew .introSection .content .buttonsContainer .btn_primary:first-child {
      margin-right: 22px; }
  .homepageContainerNew .introSection .background {
    width: 55%;
    height: 100%;
    background-image: url("../assets/illustration-1.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center; }

.homepageContainerNew .forVolunteerSection {
  width: 100%;
  height: 708px;
  background-color: #1a3465; }
  .homepageContainerNew .forVolunteerSection .content {
    width: 45%;
    margin-left: 60px;
    display: flex;
    align-items: center;
    justify-content: center; }
    .homepageContainerNew .forVolunteerSection .content > div {
      max-width: 430px; }
      .homepageContainerNew .forVolunteerSection .content > div h1 {
        color: white; }
      .homepageContainerNew .forVolunteerSection .content > div p {
        color: white; }
        .homepageContainerNew .forVolunteerSection .content > div p.small {
          margin-bottom: 24px; }
      .homepageContainerNew .forVolunteerSection .content > div .line {
        width: 46px;
        height: 2px;
        background-color: white;
        margin-bottom: 30px; }
  .homepageContainerNew .forVolunteerSection .background {
    flex-shrink: 0;
    width: 55%;
    height: 100%;
    background-image: url("../assets/illustration-2.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center; }

.homepageContainerNew .networkSection {
  height: 907px;
  background-color: #fdfaf5; }
  .homepageContainerNew .networkSection .content .head {
    overflow: auto;
    text-align: center; }
    .homepageContainerNew .networkSection .content .head p {
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      margin-top: 90px;
      margin-bottom: 24px; }
    .homepageContainerNew .networkSection .content .head h1 {
      font-weight: 800;
      font-size: 60px;
      line-height: 68px;
      margin-bottom: 84px; }
  .homepageContainerNew .networkSection .content .section {
    display: flex;
    align-items: center;
    justify-content: space-around; }
    .homepageContainerNew .networkSection .content .section img {
      width: 55%; }
    .homepageContainerNew .networkSection .content .section .contentSection {
      margin-left: 60px; }
      .homepageContainerNew .networkSection .content .section .contentSection h1 {
        margin-bottom: 28px; }
      .homepageContainerNew .networkSection .content .section .contentSection p {
        margin-bottom: 24px; }
      .homepageContainerNew .networkSection .content .section .contentSection a {
        color: #232933; }

.homepageContainerNew .reviewsSection {
  height: 820px;
  background-color: #9c3875; }
  .homepageContainerNew .reviewsSection .profile {
    width: 55%;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center; }
    .homepageContainerNew .reviewsSection .profile .background {
      box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.25);
      border-radius: 50px;
      padding: 32px;
      background-color: white; }
      .homepageContainerNew .reviewsSection .profile .background img {
        width: 100%; }
  .homepageContainerNew .reviewsSection .section {
    width: 45%;
    height: 100%;
    flex-shrink: 0;
    margin-left: 126px;
    display: flex;
    flex-direction: column;
    justify-content: center; }
    .homepageContainerNew .reviewsSection .section h1 {
      color: white;
      white-space: break-spaces; }
    .homepageContainerNew .reviewsSection .section .reviews {
      display: flex;
      overflow: auto;
      scroll-snap-type: x mandatory;
      margin-bottom: 44px; }
      .homepageContainerNew .reviewsSection .section .reviews .review {
        width: 100%;
        margin-right: 48px;
        flex-shrink: 0;
        scroll-snap-align: start; }
        .homepageContainerNew .reviewsSection .section .reviews .review > p {
          font-family: 'Petrona', sans-serif !important;
          color: white;
          font-weight: 800;
          font-size: 24px;
          line-height: 27px;
          max-width: 320px; }
        .homepageContainerNew .reviewsSection .section .reviews .review .by {
          display: flex;
          align-items: center; }
          .homepageContainerNew .reviewsSection .section .reviews .review .by > img {
            margin-right: 8px; }
          .homepageContainerNew .reviewsSection .section .reviews .review .by .byName p {
            margin: 0;
            text-align: left; }
            .homepageContainerNew .reviewsSection .section .reviews .review .by .byName p:nth-child(1) {
              font-weight: 400;
              font-size: 15px;
              line-height: 22px;
              color: white; }
            .homepageContainerNew .reviewsSection .section .reviews .review .by .byName p:nth-child(2) {
              font-weight: 300;
              font-size: 12px;
              line-height: 18px;
              color: white; }
    .homepageContainerNew .reviewsSection .section .buttonsContainer {
      display: flex;
      align-items: center;
      gap: 14px; }
      .homepageContainerNew .reviewsSection .section .buttonsContainer > button {
        all: unset;
        width: 48px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: #eeeeee;
        cursor: pointer; }

.homepageContainerNew .forCharitiesSection {
  height: 708px;
  background: linear-gradient(90deg, #2e846c 50%, #edba42 50%); }
  .homepageContainerNew .forCharitiesSection .content {
    flex-shrink: 0;
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 60px; }
    .homepageContainerNew .forCharitiesSection .content > div {
      max-width: 403px; }
      .homepageContainerNew .forCharitiesSection .content > div h1 {
        color: white; }
      .homepageContainerNew .forCharitiesSection .content > div p {
        color: white; }
        .homepageContainerNew .forCharitiesSection .content > div p.small {
          margin-bottom: 24px; }
      .homepageContainerNew .forCharitiesSection .content > div .line {
        width: 46px;
        height: 2px;
        background-color: white;
        margin-bottom: 44px; }
  .homepageContainerNew .forCharitiesSection .background {
    background-color: #edba42;
    width: 50%;
    height: 100%;
    flex-shrink: 0;
    background-image: url("../assets/illustration-4.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom center; }
