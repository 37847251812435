@import "src/styles/_defines";

.iconButton {
  height: 40px !important;
  width: 40px !important;
  padding: 8px !important;
}

.iconFilled {
  background-color: $primary_color;
  border-radius: 50%;
}