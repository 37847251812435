.eventDetailsContainer .eventHeader {
  min-height: 120px;
  padding: 20px 20px 0px 20px;
  z-index: 10;
  background-color: #ffffff;
  margin-top: 80px; }
  .eventDetailsContainer .eventHeader h1 {
    margin-bottom: 5px;
    margin-top: 0px;
    color: #212b35; }
  .eventDetailsContainer .eventHeader p {
    margin: 0px; }
  .eventDetailsContainer .eventHeader .causeName {
    color: #637381; }
  .eventDetailsContainer .eventHeader .pastEvent {
    color: #3042d4;
    font-weight: 700; }
  .eventDetailsContainer .eventHeader .headerButtonsContainer {
    padding: 15px;
    text-align: right; }
    .eventDetailsContainer .eventHeader .headerButtonsContainer .btnApply {
      margin-left: 15px; }
    .eventDetailsContainer .eventHeader .headerButtonsContainer .btnAttending {
      margin-left: 15px; }
    .eventDetailsContainer .eventHeader .headerButtonsContainer .applyBeforeDate {
      margin-top: 7px;
      color: #b5bdc4;
      text-align: right; }
    .eventDetailsContainer .eventHeader .headerButtonsContainer .contentButtons {
      display: flex;
      justify-content: flex-end; }
      .eventDetailsContainer .eventHeader .headerButtonsContainer .contentButtons .shareContainer {
        display: flex;
        align-items: center;
        height: 50px;
        margin-right: 50px;
        cursor: pointer; }
        .eventDetailsContainer .eventHeader .headerButtonsContainer .contentButtons .shareContainer p {
          font-weight: 700;
          margin-left: 10px; }
        .eventDetailsContainer .eventHeader .headerButtonsContainer .contentButtons .shareContainer:hover p {
          text-decoration: underline; }
      .eventDetailsContainer .eventHeader .headerButtonsContainer .contentButtons .missedEventsContainer {
        display: flex;
        flex-direction: column;
        justify-content: center; }
        .eventDetailsContainer .eventHeader .headerButtonsContainer .contentButtons .missedEventsContainer p {
          margin: 0px;
          color: #3042d4; }
        .eventDetailsContainer .eventHeader .headerButtonsContainer .contentButtons .missedEventsContainer h4 {
          color: #b5bdc4;
          margin: 0px; }
  .eventDetailsContainer .eventHeader.fixedHeader {
    position: fixed;
    margin-top: 0px;
    background-color: #F2F2F2; }

.eventDetailsContainer .content.fixedHeader {
  margin-top: 200px; }

.eventDetailsContainer .content .defaultMainRootContentContainer {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  float: left; }

.eventDetailsContainer .content .leftContainer .photosContainer {
  width: 100%;
  min-height: 337px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; }

.eventDetailsContainer .content .leftContainer .aboutContainer {
  margin-top: 40px; }
  .eventDetailsContainer .content .leftContainer .aboutContainer p {
    color: #637381; }

.eventDetailsContainer .content .leftContainer .reviewsContainer {
  margin-top: 40px; }
  .eventDetailsContainer .content .leftContainer .reviewsContainer .quotes {
    transform: scale(-1);
    -moz-transform: scale(-1);
    -webkit-transform: scale(-1);
    -ms-transform: scale(-1);
    font-size: 80px;
    color: #20c1c9; }
  .eventDetailsContainer .content .leftContainer .reviewsContainer .quotesContent {
    text-align: left;
    color: #212b35; }
  .eventDetailsContainer .content .leftContainer .reviewsContainer .quotesAuthor {
    margin-top: 10px;
    color: #637381; }

.eventDetailsContainer .content .leftContainer .attendeesContainer {
  margin-top: 40px; }
  .eventDetailsContainer .content .leftContainer .attendeesContainer .attendeesHeader {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .eventDetailsContainer .content .leftContainer .attendeesContainer .attendeesHeader p {
      color: #637381; }

.eventDetailsContainer .content .leftContainer .faqsContentContainer {
  margin-top: 40px; }
  .eventDetailsContainer .content .leftContainer .faqsContentContainer .answer {
    color: #637381; }

.eventDetailsContainer .content .leftContainer .commentsContainer {
  margin-top: 40px; }
  .eventDetailsContainer .content .leftContainer .commentsContainer .commentsBox {
    border: 1px solid #e4eaf7;
    padding: 0px 20px 20px 20px; }
    .eventDetailsContainer .content .leftContainer .commentsContainer .commentsBox .helpText {
      color: #637381; }
    .eventDetailsContainer .content .leftContainer .commentsContainer .commentsBox .btnComment {
      margin-top: 15px;
      min-width: 110px !important; }
  .eventDetailsContainer .content .leftContainer .commentsContainer .commentContent {
    margin-top: 10px; }
    .eventDetailsContainer .content .leftContainer .commentsContainer .commentContent .comment {
      border-bottom: 1px solid #e4eaf7;
      display: flex;
      align-items: center;
      padding: 25px 0px 25px 0px; }
      .eventDetailsContainer .content .leftContainer .commentsContainer .commentContent .comment .userImageContainer {
        margin-right: 20px; }
        .eventDetailsContainer .content .leftContainer .commentsContainer .commentContent .comment .userImageContainer .userPic {
          width: 85px;
          height: 85px;
          border-radius: 50%;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover; }
      .eventDetailsContainer .content .leftContainer .commentsContainer .commentContent .comment .dataContainer {
        width: 100%; }
        .eventDetailsContainer .content .leftContainer .commentsContainer .commentContent .comment .dataContainer .firstRowContainer {
          display: flex;
          align-items: center;
          justify-content: space-between; }
          .eventDetailsContainer .content .leftContainer .commentsContainer .commentContent .comment .dataContainer .firstRowContainer .fullname {
            margin: 0px; }
          .eventDetailsContainer .content .leftContainer .commentsContainer .commentContent .comment .dataContainer .firstRowContainer .buttonsContainer a {
            font-size: 14px; }
        .eventDetailsContainer .content .leftContainer .commentsContainer .commentContent .comment .dataContainer .message {
          color: #637381;
          margin: 0px; }
        .eventDetailsContainer .content .leftContainer .commentsContainer .commentContent .comment .dataContainer .date {
          color: #b5bdc4;
          margin: 0px;
          font-size: 12px; }

.eventDetailsContainer .content .eventsForCharity {
  background-color: #faf7f5;
  margin-top: 100px; }
  .eventDetailsContainer .content .eventsForCharity h2 {
    margin-bottom: 40px; }

.eventDetailsContainer .content .rightContainer .charityContainer {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e4eaf7; }
  .eventDetailsContainer .content .rightContainer .charityContainer .pictureContainer {
    margin-bottom: 20px; }
    .eventDetailsContainer .content .rightContainer .charityContainer .pictureContainer .imgCharity {
      width: 60px;
      height: 60px;
      border-radius: 50%; }
  .eventDetailsContainer .content .rightContainer .charityContainer .charityContentContainer {
    margin-bottom: 20px;
    margin-left: 10px; }
    .eventDetailsContainer .content .rightContainer .charityContainer .charityContentContainer h2 {
      margin-bottom: 5px;
      margin-top: 10px; }
    .eventDetailsContainer .content .rightContainer .charityContainer .charityContentContainer p {
      margin-top: 0px; }
    .eventDetailsContainer .content .rightContainer .charityContainer .charityContentContainer a {
      text-decoration: none;
      color: #212b35;
      font-size: 21px;
      line-height: 29px; }

.eventDetailsContainer .content .rightContainer .skillsNeededContainer {
  border-bottom: 1px solid #e4eaf7; }

.eventDetailsContainer .content .rightContainer .eventTimeContainer, .eventDetailsContainer .content .rightContainer .locationContainer {
  margin-top: 40px;
  display: flex;
  align-items: flex-start; }
  .eventDetailsContainer .content .rightContainer .eventTimeContainer p, .eventDetailsContainer .content .rightContainer .locationContainer p {
    margin: 0px 0px 10px 0px; }
  .eventDetailsContainer .content .rightContainer .eventTimeContainer svg, .eventDetailsContainer .content .rightContainer .locationContainer svg {
    margin-top: 5px; }

.eventDetailsContainer .content .rightContainer .locationContainer {
  border-bottom: 1px solid #e4eaf7; }

@media screen and (min-width: 1170px) {
  .eventDetailsContainer .content .defaultMainRootContentContainer {
    float: none;
    padding-left: 0px;
    padding-right: 0px; } }

@media screen and (max-width: 1169px) {
  .eventDetailsContainer .eventHeader {
    margin-top: 20px; }
  .eventDetailsContainer .content.fixedHeader {
    margin-top: 140px; } }

@media screen and (max-width: 991px) {
  .eventDetailsContainer .eventHeader .headerButtonsContainer .contentButtons .shareContainer {
    margin-right: 15px; }
  .eventDetailsContainer .eventHeader .headerButtonsContainer .btnDefault {
    min-width: 140px !important; }
  .eventDetailsContainer .eventHeader .headerButtonsContainer .btnSave {
    min-width: 130px !important; } }

@media screen and (max-width: 767px) {
  .eventDetailsContainer .eventHeader .headerButtonsContainer {
    text-align: left;
    margin-top: 15px; }
    .eventDetailsContainer .eventHeader .headerButtonsContainer .contentButtons .shareContainer p {
      font-size: 14px; }
    .eventDetailsContainer .eventHeader .headerButtonsContainer .btnDefault {
      min-width: 120px !important; }
      .eventDetailsContainer .eventHeader .headerButtonsContainer .btnDefault span {
        font-size: 12px !important; }
    .eventDetailsContainer .eventHeader .headerButtonsContainer .btnSave {
      min-width: 105px !important; } }

.modalMap {
  height: 600px; }
  .modalMap .modalMapContent {
    height: 600px;
    padding: 0px !important; }
