.deleteTaskModal {
  .modalPaper {
    width: 600px;
    padding: 0 100px;
    margin: auto;
  }

  .row {
    padding: 8px 0;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #eeeeee;
  }

  .modalClose {
    display: none;
  }
}
