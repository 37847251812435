.reviewEventContainer {
  text-align: center; }
  .reviewEventContainer h2 {
    color: #637381; }
  .reviewEventContainer .stepperContainer {
    max-width: 500px;
    margin-left: auto;
    margin-right: auto; }
  .reviewEventContainer .content {
    padding: 80px 0px;
    background-color: #f1f3f6; }
  .reviewEventContainer .rating {
    margin-bottom: 40px; }
    .reviewEventContainer .rating .star {
      width: 50px !important;
      height: 50px !important;
      color: #212b35 !important; }
  .reviewEventContainer .confirmedUsersContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px 0px 20px 0px;
    flex-wrap: wrap;
    background-color: #ffffff;
    margin-bottom: 40px; }
    .reviewEventContainer .confirmedUsersContainer .user {
      width: 160px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-right: 1px solid #e4eaf7;
      flex: 1 0 20%;
      margin-bottom: 40px; }
      .reviewEventContainer .confirmedUsersContainer .user .picture {
        width: 85px;
        height: 85px;
        border-radius: 50%;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat; }
      .reviewEventContainer .confirmedUsersContainer .user h3 {
        margin-bottom: 5px; }
      .reviewEventContainer .confirmedUsersContainer .user .ratingUser {
        margin-bottom: 5px; }
        .reviewEventContainer .confirmedUsersContainer .user .ratingUser .star {
          width: 20px !important;
          height: 20px !important; }
      .reviewEventContainer .confirmedUsersContainer .user .userReview {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
        margin-left: 12px; }
        .reviewEventContainer .confirmedUsersContainer .user .userReview a {
          font-size: 14px;
          margin-right: 5px; }
        .reviewEventContainer .confirmedUsersContainer .user .userReview .reviewIcon {
          margin-top: 5px; }
      .reviewEventContainer .confirmedUsersContainer .user .numberInputContainer {
        width: 120px !important;
        height: 30px; }
        .reviewEventContainer .confirmedUsersContainer .user .numberInputContainer button {
          width: 30px !important;
          height: 30px !important;
          padding: 5px !important; }
        .reviewEventContainer .confirmedUsersContainer .user .numberInputContainer svg {
          width: 18px !important;
          height: 18px !important; }
        .reviewEventContainer .confirmedUsersContainer .user .numberInputContainer .result {
          font-size: 12px;
          font-weight: 700; }
          .reviewEventContainer .confirmedUsersContainer .user .numberInputContainer .result span {
            color: #212b35; }
          .reviewEventContainer .confirmedUsersContainer .user .numberInputContainer .result:after {
            content: ' hours'; }
  .reviewEventContainer .buttonsContainer {
    margin-top: 40px; }
  .reviewEventContainer .selectAttendesContainer .confirmedUsersContainer .user {
    min-height: 170px;
    justify-content: flex-start; }
    .reviewEventContainer .selectAttendesContainer .confirmedUsersContainer .user a {
      display: flex;
      flex-direction: column;
      align-items: center; }
      .reviewEventContainer .selectAttendesContainer .confirmedUsersContainer .user a .checkedIcon {
        width: 36px;
        height: 36px;
        margin-top: 0px;
        margin-left: 60px; }
    .reviewEventContainer .selectAttendesContainer .confirmedUsersContainer .user.inactive .picture {
      opacity: 0.6; }
