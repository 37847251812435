.modalSendEmailAttendees {
  max-width: 400px !important; }
  .modalSendEmailAttendees a {
    position: absolute;
    top: 20px;
    right: 20px; }
  .modalSendEmailAttendees h2 {
    margin-top: 50px;
    font-weight: 700;
    color: #212b35; }
  .modalSendEmailAttendees .btnSend {
    float: right;
    margin-top: 40px;
    margin-bottom: 10px; }
