@import 'src/styles/_defines';

.messageDrawer {
  top: 60px !important;
  padding: 0px 0px 25px 0px;
  p {
    margin: 0px;
  }
  overflow: hidden;
  -webkit-animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;

  // @-webkit-keyframes slide-in-right {
  //   0% {
  //     -webkit-transform: translateX(1000px);
  //     transform: translateX(1000px);
  //     opacity: 0;
  //   }
  //   100% {
  //     -webkit-transform: translateX(0);
  //     transform: translateX(0);
  //     opacity: 1;
  //   }
  // }
  // @keyframes slide-in-right {
  //   0% {
  //     -webkit-transform: translateX(1000px);
  //     transform: translateX(1000px);
  //     opacity: 0;
  //   }
  //   100% {
  //     -webkit-transform: translateX(0);
  //     transform: translateX(0);
  //     opacity: 1;
  //   }
  // }

  .messageHeader {
    height: 60px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    h2 {
      font-size: 14px;
      font-weight: 700;
    }
    .contactDetailsContainer {
      text-align: right;
      display: flex;
      flex-direction: column;
      p {
        margin: 0px;
      }
      a {
        font-size: 10px;
        margin: 0px;
      }
    }
  }
  .messagesContentContainer {
    min-height: calc(100% - 286px);
    height: calc(100% - 286px);
    overflow: auto;

    &.isScrolled {
      scroll-behavior: smooth;
    }
    .dateContainer {
      border-top: 1px solid $grey_light;
      padding: 25px 50px;
      display: flex;
      flex-direction: column;
      .dateTitle {
        font-weight: 700;
        color: $grey_light;
      }
      &:first-child {
        border-top: none;
      }
      .messageBox {
        width: 275px;
        min-width: 275px;
        padding: 14px 18px 18px 18px;
        margin-top: 25px;
        border-radius: 6px;
        display: flex;
        flex-direction: column;
        .body {
          font-size: 14px;
          // overflow-x: hidden;
          // text-overflow: ellipsis;
          white-space: pre-line;
          word-break: break-word;
          -webkit-hyphens: auto;
          -ms-hyphens: auto;
          hyphens: auto;
          a {
            max-width: 100%;
            overflow-x: hidden;
            text-overflow: ellipsis;
            display: inline-block;
            white-space: nowrap;
            vertical-align: bottom;
          }
        }
        .user {
          font-size: 16px;
          font-weight: 700;
          margin-bottom: 8px;
        }
        .additionalInfo {
          display: flex;
          align-items: center;
          .time {
            margin-right: 15px;
          }
          p {
            font-size: 11px;
          }
        }
        &.sent {
          background-color: #5077ff;
          align-self: flex-end;
          p {
            color: $white;
          }
          a {
            color: $white;
          }
        }
        &.received {
          border: 1px solid #aaa;
          .time {
            color: $grey_light;
          }
        }
      }
    }
    .systemMessage {
      margin: 15px 10px;
      display: flex;
      flex-direction: row;
      .body {
        white-space: pre-line;
        word-break: break-word;
        -webkit-hyphens: auto;
        -ms-hyphens: auto;
        hyphens: auto;
        font-weight: 700;
      }
      .date {
        color: #aaa;
        font-size: 11px;
      }
      img {
        width: 16px;
        min-width: 16px;
        height: 20px;
      }
      .contentMessage {
        margin-left: 10px;
      }
    }
    #anchorPoint {
      opacity: 0;
      cursor: default;
    }
  }
  .messageTextBox {
    margin: 0px 25px;
    border: 1px solid $blue_solid_light;
    border-radius: 10px;
    height: 90px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    .actionsContainer {
      margin: 12px;
      display: flex;
      justify-content: flex-end;
      .sendMessageButton {
        max-width: 105px;
      }
      .sendMessageIcon {
        width: 38px;
        min-width: 38px !important;
        margin-bottom: 4px;
      }
    }
  }
}
