.backButton {
  position: absolute;
  left: 40px;
  top: 20px;
  font-size: 32px;
  color: #3042d4;
  cursor: pointer;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #3042d4;
  background-color: transparent;
  padding: 0px 25px 0px 15px;
  border-radius: 4px;
  text-decoration: none;
  text-transform: none; }
  .backButton.noBorder {
    border: none !important; }
  .backButton p {
    margin-left: 10px; }
  .backButton:hover {
    color: #4f4f4f;
    text-decoration: none;
    text-transform: none; }
