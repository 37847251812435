.networkList {
  background: #ffffff;
  border-radius: 10px;
  padding-left: 45px;
  padding-right: 45px;
  padding-top: 40px;
  padding-bottom: 40px;
  margin-bottom: 36px;

  .networkUser {
    height: 140px;
    text-decoration: none;
    color: black;
    font-weight: 400;

    .networkProfileImg {
      height: 80px;
      width: 80px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #faf7f5 !important;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      object-fit: cover;
    }
  }
}
