@import 'src/styles/_defines';

.sidebarContainer {
  position: fixed;
  height: 100%;
  display: flex;
  flex-flow: column;
  background: $blue_dark;
  width: 250px;
  min-width: 250px;
  border-right: 1px solid $blue_dark;
  ul {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0px;
    padding-bottom: 67px;
    margin: 0;
    margin-top: 35px;
  }
  .charityLogoContainer {
    display: flex;
    align-items: center;
    flex-direction: row;
    font-size: 15px;
    padding: 50px 35px 0px 35px;
    color: $white;
    margin-bottom: 0px;
  }
  .charityLogo {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 15px;
  }
  .sidebarItem {
    font-size: 14px;
    display: flex;
    cursor: pointer;
    margin-bottom: 4px;
    text-decoration: none;
    align-items: center;
    padding: 10px 35px;
    span {
      color: $white;
      margin-left: 12px;
    }
    &.selected {
      background-color: #c1c7ff;
      span {
        color: $primary_body;
      }
    }
    &.last {
      margin-top: auto;
      margin-bottom: 57px;
    }
    .imageContainer {
      img {
        height: 38px;
        width: 38px;
      }
    }
  }
}
