.shareContainer {
  max-width: 500px !important;
  text-align: center; }
  .shareContainer .closeButton {
    position: absolute;
    top: 20px;
    right: 20px; }
  .shareContainer .content {
    padding: 40px 40px 30px 40px; }
    .shareContainer .content h2 {
      font-weight: 700;
      color: #3042d4; }
    .shareContainer .content .socialMediaContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 40px 0px; }
      .shareContainer .content .socialMediaContainer .SocialMediaShareButton {
        margin-right: 15px; }
