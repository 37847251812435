@import 'src/styles/_defines';

.charityManagerSearch {
  background-color: $grey_bg_2;
  border-radius: 20px;
  display: flex;
  align-items: center;
  min-width: 560px;

  img {
    height: 20px;
    width: 20px !important;
    margin-left: 24px;
  }

  input {
    margin-bottom: 0;
    border: none;
    background-color: transparent;
    color: #6e7581;
    padding-top: 10px;
    padding-bottom: 10px;

    &:focus {
      outline: none;
    }
  }
}

.searchPopover {
  padding: 24px 0;
  border-radius: 8px !important;
  max-height: 500px !important;
  overflow: scroll;
  min-width: 400px;
}
