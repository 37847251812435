@import 'src/styles/_defines';

.headerSimpleContainer {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  a {
    text-decoration: none;
  }
  img {
    width: 150px;
  }
  @media screen and (max-width: $screen-xs-max) {
    height: 90px;
    img {
      width: 110px;
    }
  }
}
