.adminContainer .adminHeaderContainer {
  margin: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center; }

.adminContainer .buttonsContainer {
  margin: 40px; }
  .adminContainer .buttonsContainer .btn_primary {
    min-width: 120px !important;
    margin-left: 20px; }
