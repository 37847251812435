.mocalCancelAttendance {
  max-width: 400px !important; }
  .mocalCancelAttendance a {
    position: absolute;
    top: 20px;
    right: 20px; }
  .mocalCancelAttendance p {
    margin-top: 80px;
    font-weight: 700;
    color: #212b35; }
  .mocalCancelAttendance .btnOK {
    margin: 60px 0px; }
