.btn_primary {
  box-shadow: none !important; }
  .btn_primary svg {
    margin-bottom: 5px; }
  .btn_primary img {
    padding-left: 16px;
    margin-left: 0 !important; }
  .btn_primary img + span {
    padding-left: 12px !important; }
  .btn_primary span {
    font-weight: 700 !important; }

.borderedButton {
  display: inline-block;
  border: 1px solid #3042d4;
  border-radius: 3px;
  background-color: #ffffff;
  min-width: 88px;
  text-decoration: none; }
  .borderedButton .borderedButtonContent {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 42px; }
    .borderedButton .borderedButtonContent svg {
      color: #3042d4 !important;
      margin-right: 5px; }
  .borderedButton p {
    color: #3042d4;
    font-weight: 700; }
    .borderedButton p:hover {
      color: #3042d4; }
  .borderedButton:hover {
    text-decoration: none;
    opacity: 0.9; }

.btn_transparent {
  background-color: transparent !important; }
  .btn_transparent > button {
    background-color: transparent !important;
    border: 1px solid #fff !important; }
  .btn_transparent.dark > button {
    border: 1px solid #000 !important; }
    .btn_transparent.dark > button span {
      color: #000 !important; }
  .btn_transparent.primary > button {
    border: 1px solid #3042d4 !important; }
    .btn_transparent.primary > button span {
      color: #3042d4 !important;
      font-weight: 600 !important; }
