.googleInput {
  position: relative !important; }
  .googleInput .normalInput {
    width: 100%;
    border: none;
    resize: none;
    outline: none;
    margin: 15px 0 10px 0;
    font-size: 16px;
    overflow: hidden;
    transition: height 0.1s ease; }
    .googleInput .normalInput::placeholder {
      color: #828282; }
