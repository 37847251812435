.infoContainer {
  padding: 40px;
  border: 1px solid #e4eaf7;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center; }
  .infoContainer .content {
    max-width: 400px;
    text-align: center; }
  .infoContainer h2 {
    color: #3042d4;
    font-weight: 400 !important;
    margin-top: 15px !important; }
  .infoContainer h3 {
    color: #637381;
    font-weight: 400; }
  .infoContainer .infoBtn span {
    text-transform: none !important; }
