.forCharitiesContainerNew .wrapperContent {
  max-width: 1280px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  margin: auto;
  height: 100%; }

.forCharitiesContainerNew .content h1 {
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 41px;
  color: black; }

.forCharitiesContainerNew .content p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px; }
  .forCharitiesContainerNew .content p.small {
    font-size: 14px !important;
    line-height: 21px !important; }

.forCharitiesContainerNew .content .line {
  width: 46px;
  height: 2px;
  background-color: white;
  margin-bottom: 44px; }

.forCharitiesContainerNew .introSection {
  width: 100%;
  height: 650px;
  margin-top: 60px;
  background-color: #f1efeb; }
  .forCharitiesContainerNew .introSection .content {
    width: 50%;
    margin-right: 60px; }
    .forCharitiesContainerNew .introSection .content h1 {
      font-size: 60px;
      line-height: 68px; }
    .forCharitiesContainerNew .introSection .content p {
      max-width: 443px; }
  .forCharitiesContainerNew .introSection .background {
    width: 50%;
    height: 100%;
    background-image: url("../assets/illustration-1.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom; }

.forCharitiesContainerNew .assignTasksSection {
  height: 708px;
  background: linear-gradient(90deg, #edba42 50%, #009e95 50%); }
  .forCharitiesContainerNew .assignTasksSection .content {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 60px; }
    .forCharitiesContainerNew .assignTasksSection .content > div {
      width: 100%;
      max-width: 403px; }
      .forCharitiesContainerNew .assignTasksSection .content > div h1 {
        color: white; }
      .forCharitiesContainerNew .assignTasksSection .content > div p {
        color: white; }
        .forCharitiesContainerNew .assignTasksSection .content > div p.small {
          margin-bottom: 24px; }
  .forCharitiesContainerNew .assignTasksSection .background {
    width: 50%;
    height: 100%;
    background-image: url("../assets/illustration-2.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: left; }

.forCharitiesContainerNew .manageTasksSection {
  height: 730px;
  background-color: #f1efeb; }
  .forCharitiesContainerNew .manageTasksSection .content {
    width: 33.33%;
    margin-right: 140px; }
    .forCharitiesContainerNew .manageTasksSection .content p {
      font-weight: 400;
      font-size: 16px;
      line-height: 30px; }
    .forCharitiesContainerNew .manageTasksSection .content .line {
      background-color: black; }
  .forCharitiesContainerNew .manageTasksSection .screenshotContainer {
    width: 66.66%;
    display: flex;
    align-items: center;
    justify-content: flex-end; }
    .forCharitiesContainerNew .manageTasksSection .screenshotContainer .screenshot {
      background-color: #eae7e1;
      border-radius: 20px;
      padding: 31px 31px 40px 31px; }
      .forCharitiesContainerNew .manageTasksSection .screenshotContainer .screenshot img {
        width: 100%; }

.forCharitiesContainerNew .charityNetworksSection {
  height: 708px;
  background-color: #1a3465; }
  .forCharitiesContainerNew .charityNetworksSection .content {
    width: 40%;
    margin-right: 60px; }
    .forCharitiesContainerNew .charityNetworksSection .content h1,
    .forCharitiesContainerNew .charityNetworksSection .content p {
      color: white; }
  .forCharitiesContainerNew .charityNetworksSection .imageContainer {
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: center; }
    .forCharitiesContainerNew .charityNetworksSection .imageContainer img {
      width: 100%; }

.forCharitiesContainerNew .outroSection {
  background-color: #f1efeb;
  height: 500px; }
  .forCharitiesContainerNew .outroSection .content {
    width: 50%;
    max-width: 430px;
    margin-left: 60px; }
  .forCharitiesContainerNew .outroSection .background {
    width: 50%;
    height: 100%;
    background-image: url("../assets/illustration-5.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: left bottom; }
