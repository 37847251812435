.confirmModal {
  padding: 0px;
  max-width: 610px !important;
  
  &.existing {
    max-width: 729px !important;
  }
  
  &>div {
    border-radius: 16px !important;

    &>div {
      padding: 0px !important;
    }
  }

  p.inputLabel {
    text-align: left;
    color: black;
    font-size: 14px;
    margin: 0px 0px 14px;
  }
  p.text {
    text-align: center;
    color: black;
    font-size: 14px;
    margin: 0px 0px 14px;
  }

  .settingModalFormContainer {
    padding: 44px 80px 25px;

    .settingModalFormHeader {
      font-size: 30px;
      line-height: 33.84px;
      text-align: center;
      color: #212b35;
      font-weight: 800;
      margin-bottom: 31px;
    }
    .buttonGroup {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding-top: 16px;
      gap: 32px;
    }
  }
  .inviteFormContainer {
    padding: 53px;
    position: relative;
    span.closeButton {
      position: absolute;
      top: 10px;
      right: 10px;
      font-size: 24px;
      color: black;
      cursor: pointer;
    }
    .inviteFormHeader {
      font-size: 30px;
      line-height: 33.84px;
      text-align: center;
      color: #212b35;
      font-weight: 800;
      margin-bottom: 22px;
    }
    .inviteFormText {
      font-size: 14px;
      line-height: 27px;
      color: #000000;
      font-weight: 300;

      &.existing {
        font-size: 16px;
        font-family: Poppins !important;
        font-weight: 700;
      }
    }
    .searchContentBlock {
      display: flex;
      flex-direction: column;
      margin-bottom: 28px;
      max-height: 305px;
      height: fit-content;
      overflow-y: auto;
      .listItem {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 16px;
        cursor: pointer;
        &>img {
          width: 36px;
          height: 36px;
          border-radius: 50%;
          margin-right: 17px;
        }
        .volunteerInfo {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          margin-right: 17px;
          span {
            &:first-child {
              color: black;
            }
          }
        }
        .skillBadge {
          padding: 4px 8px;
          border-radius: 5px;
          margin: 3px;
          font-size: 12px;
        }
        .macroBadge {
          padding: 8px 16px;
          border-radius: 5px;
          margin: 3px;
          font-size: 12px;
        }
        .addIcon {
          position: absolute;
          right: 10px;
        }
      }
    }
  }
  .removeFormContainer {
    padding: 44px 50px 25px;
    .formHeader {
      font-size: 30px;
      line-height: 33.84px;
      text-align: center;
      color: #212b35;
      font-weight: 800;
      margin-bottom: 15px;
    }
    .formText {
      font-size: 14px;
      line-height: 27px;
      color: #000000;
      font-weight: 300;
      margin-top: 16px;
      margin-bottom: 20px;
    }
  }

  .modalClose {
    position: absolute;
    top: 24px;
    right: 24px;
    z-index: 999;
  }
}

.switchSettingContainer {
  .switchSettingForm {
    display: flex;
    width: 100%;
    align-items: flex-start;

    .descriptionContainer {
      display: flex;
      flex-direction: column;
      width: 80%;
      
      p {
        font-family: 'Poppins';
        font-size: 14px;
        font-weight: 400;
        margin-top: 0px;
    
        &.title {
          color: black;
          margin-bottom: 13px;
        }
    
        &.detail {
          color: #B5BDC4;
          margin-bottom: 17px;
        }
      }
    }
    .switchComponent {
      display: flex;
      flex-direction: row;
      align-items: center;

      span {
        color: black;
        padding-right: 15px;
      }
    }
  }
  hr {
    margin-top: 5px;
    margin-bottom: 22px;
  }
}