@import 'src/styles/_defines';
@import 'src/styles/_layout';

.welcomeContainer {
  padding-top: 108px;
  padding-bottom: 108px;
  padding-left: 85px;

  .title {
    font-family: Petrona;
    font-size: 30px;
    font-weight: 800;
    line-height: 34px;
    letter-spacing: 0em;
    text-align: left;
  }

  .subTitle {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
  }

  .header {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 700;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
  }
}
