@import 'src/styles/_defines';

.charityDetailsContainer {
  background-color: #eeeeee;
  padding-top: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 56px;

  h1,
  h3,
  h4,
  h5 {
    font-family: 'Poppins', sans-serif !important;
  }

  .chip {
    background-color: white !important;
  }

  .header-background {
    height: 320px;
    background-color: $blue_dark;
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 1;
  }

  .container {
    max-width: 1274px;
    width: 100%;
    padding: 0;

    .sectionContainer {
      background: #ffffff;
      border-radius: 10px;
      padding-left: 45px;
      padding-right: 45px;
      padding-top: 40px;
      padding-bottom: 40px;
      margin-bottom: 36px;
    }

    .subTitle {
      font-family: Poppins;
      font-size: 17px;
      font-weight: 400;
      line-height: 30px;
      letter-spacing: 0em;
      text-align: center;
    }

    .content {
      font-family: Poppins;
      font-size: 15px;
      font-weight: 400;
      line-height: 26px;
      letter-spacing: 0em;
      text-align: center;
      color: #828282;
    }

    .charityManagerLogo {
      width: 84px;
      height: 84px;
      border-radius: 50%;
      object-fit: cover;
      border: 1px solid #e3e5e5;
    }

    .charityManagerName {
      font-family: Poppins;
      font-size: 16px;
      font-weight: 400;
      line-height: 28px;
      letter-spacing: 0em;
      text-align: left;
    }

    .charityManager {
      font-family: Poppins;
      font-size: 16px;
      font-weight: 400;
      line-height: 28px;
      letter-spacing: 0em;
      text-align: left;
      color: #828282;
    }

    .charityManagerDescription {
      font-family: Poppins;
      font-size: 15px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0em;
      text-align: center;
      color: #828282;
    }
  }

  .header-card {
    z-index: 2;
    padding: 40px 48px;
    background-color: white;
    border-radius: 10px;
    max-width: 1274px;
    width: 100%;
    margin-top: 50px;
    margin-bottom: 56px;
    display: flex;
    justify-content: center;
    overflow: hidden;
    align-items: start;

    .headerCardInner {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      padding: 0;

      > img,
      > div {
        margin: 8px 16px;
      }
    }

    .charity-logo {
      width: 184px;
      height: 184px;
      border-radius: 50%;
      object-fit: cover;
      border: 1px solid #e3e5e5;
    }

    .dropzone {
      width: 186px !important;
      height: 186px !important;
      border-radius: 50%;
      border: 1px solid #e3e5e5;
    }
  }

  h1 {
    color: $primary_color;
  }
}
