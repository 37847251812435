@import 'src/styles/_defines';

.reviewTimesheetsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 0px !important;

  .border_bottom {
    border-bottom: 1px solid #eeeeee;
  }

  .border_top {
    margin-top: 34px;
    border-top: 1px solid #eeeeee;
    align-items: flex-end;
  }

  .title {
    font-family: Poppins;
    font-size: 24px;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: center;
    color: #3042d4;
  }

  .subject {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
  }

  .header {
    font-size: 13px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
  }

  .content {
    font-size: 13px;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
  }

  .empty-content {
    margin: 4px 0;
    font-size: 13px;
    line-height: 19px;
    font-weight: 400;
    letter-spacing: 0em;
  }

  .add_time_entry {
    font-size: 13px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
  }

  .status {
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
  }

  .btn_mark_as_complete_cover {
    display: none;
    justify-content: end;
    margin-top: 34px;
  }

  .btn_mark_as_complete {
    button {
      border-radius: 5px !important;
    }
  }

  .btn_group_approve_complete {
    display: flex;
    align-items: center;
    justify-content: end;
  }

  .btn_approve_all_cover {
    margin-top: 34px;
  }

  .btn_approve_all {
    border-radius: 5px !important;
    margin-right: 15px !important;
    div {
      border-radius: 5px !important;

      span {
        color: white;
      }
    }
  }

  .enable_approve {
    .btn_approve_all div {
      background-color: $primary_color !important;
    }
  }

  .disable_approve {
    pointer-events: none;
    cursor: not-allowed;
    .btn_approve_all div {
      background-color: $tertiary_body !important;
    }
  }

  .enable_complete {
    .btn_mark_as_complete button {
      background-color: $green_dark !important;
    }
  }

  .disable_complete {
    pointer-events: none;
    cursor: not-allowed;
    .btn_mark_as_complete button {
      background-color: $blue_solid_light !important;
    }
  }

  .btn_approve button {
    background-color: $primary_color_light !important;
    border-radius: 5px !important;
  }

  .button_layout {
    justify-content: space-between;
  }

  .iconButton {
    margin-left: 8px !important;
  }

  .edit_name {
    border: 1px solid grey !important;
    border-radius: 100px;
    background-color: #f3f5f7;
    width: -webkit-fill-available;
    margin-bottom: 0px;
    color: black;
  }

  .edit_name:focus-visible {
    border: 1px solid rgb(85, 81, 81) !important;
    outline: none;
  }

  .btn_add .btn_edit {
    min-width: 130px !important;
    width: 100%;

    button {
      border-radius: 5px !important;
    }

    div > div > span {
      padding: 0 !important;
    }
  }

  > div {
    width: 800px;
  }

  .modalPaper {
    width: 800px;
  }

  .timeSheetsContainer {
    background: #f8f8f8;
    padding: 30px;
  }
}
