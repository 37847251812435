@import 'src/styles/_layout';

.applyModal {
  .charityLogo {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
    border: 1px solid #e3e5e5;
  }

  .innerContainer {
    max-width: 504px;
    margin-left: auto;
    margin-right: auto;
  }

  .applyBtn {
    margin-top: 25px !important;
  }
}
