.charitiesContainerNew {
  padding-top: 60px; }
  .charitiesContainerNew h1 {
    font-style: normal;
    font-weight: 800;
    font-size: 36px;
    line-height: 41px;
    color: black; }
  .charitiesContainerNew p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px; }
    .charitiesContainerNew p.small {
      font-size: 14px !important;
      line-height: 21px !important; }
  .charitiesContainerNew .line {
    width: 46px;
    height: 2px;
    background-color: white;
    margin-bottom: 44px; }
  .charitiesContainerNew .searchContainer {
    background: #f7f5f0;
    width: 100%;
    padding: 65px 0 48px 0;
    overflow: auto; }
    .charitiesContainerNew .searchContainer .search {
      max-width: 1000px;
      margin: 0 auto; }
      .charitiesContainerNew .searchContainer .search h1 {
        margin: 0 0 32px 0; }
      .charitiesContainerNew .searchContainer .search .inputContainer {
        display: flex;
        align-items: center;
        margin-bottom: 40px; }
        .charitiesContainerNew .searchContainer .search .inputContainer > input {
          margin: 0 18px 0 0; }
        .charitiesContainerNew .searchContainer .search .inputContainer > .btn_primary {
          width: 126px; }
      .charitiesContainerNew .searchContainer .search .btnContainer {
        display: flex;
        align-items: center; }
        .charitiesContainerNew .searchContainer .search .btnContainer img {
          transition: 0.3s ease; }
          .charitiesContainerNew .searchContainer .search .btnContainer img.rotate {
            transform: rotate(90deg); }
        .charitiesContainerNew .searchContainer .search .btnContainer button {
          all: unset;
          cursor: pointer;
          font-family: 'Poppins', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          color: #3042d4;
          margin-left: 8px; }
      .charitiesContainerNew .searchContainer .search .causesContainer {
        padding-top: 28px; }
  .charitiesContainerNew .charityListContainer .charityLists {
    max-width: 1000px;
    margin: 0 auto;
    padding: 68px 0 108px 0; }
    .charitiesContainerNew .charityListContainer .charityLists .myNetwork {
      padding-bottom: 120px; }
    .charitiesContainerNew .charityListContainer .charityLists .sectionTitle {
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 116%;
      color: #000000; }
  .charitiesContainerNew .joinNetworkSection {
    height: 708px;
    background: #9c3875;
    display: flex; }
    .charitiesContainerNew .joinNetworkSection .content {
      flex-shrink: 0;
      width: 50%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center; }
      .charitiesContainerNew .joinNetworkSection .content > div {
        width: 100%;
        max-width: 403px; }
        .charitiesContainerNew .joinNetworkSection .content > div h1 {
          color: white;
          margin-bottom: 42px; }
        .charitiesContainerNew .joinNetworkSection .content > div p {
          color: white; }
          .charitiesContainerNew .joinNetworkSection .content > div p.small {
            margin-bottom: 24px; }
        .charitiesContainerNew .joinNetworkSection .content > div .profile {
          display: flex;
          align-items: center;
          margin-bottom: 54px; }
          .charitiesContainerNew .joinNetworkSection .content > div .profile img {
            margin-right: 10px; }
          .charitiesContainerNew .joinNetworkSection .content > div .profile > div p {
            margin: 0; }
          .charitiesContainerNew .joinNetworkSection .content > div .profile > div p:nth-child(1) {
            font-weight: 400;
            font-size: 15px;
            line-height: 22px; }
          .charitiesContainerNew .joinNetworkSection .content > div .profile > div p:nth-child(2) {
            font-weight: 300;
            font-size: 12px;
            line-height: 18px; }
    .charitiesContainerNew .joinNetworkSection .background {
      flex-shrink: 0;
      width: 50%;
      height: 100%;
      background-image: url("../assets/illustration-1.svg");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center; }
  .charitiesContainerNew .joinModal {
    position: fixed;
    inset: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 2001;
    display: flex;
    justify-content: center;
    align-items: flex-start; }
    .charitiesContainerNew .joinModal .content {
      width: 652px;
      padding: 56px 94px 56px 94px;
      background-color: white;
      border-radius: 20px;
      text-align: center;
      margin-top: 140px; }
      .charitiesContainerNew .joinModal .content h1 {
        font-family: 'Petrona';
        font-style: normal;
        font-weight: 700;
        font-size: 31px;
        line-height: 35px;
        text-align: center;
        color: #000000;
        margin-bottom: 36px; }
      .charitiesContainerNew .joinModal .content p {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 174%;
        text-align: center;
        color: #000000;
        margin-bottom: 24px; }
      .charitiesContainerNew .joinModal .content .btnLoginContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 174%;
        color: #3042d4;
        margin-top: 24px; }
        .charitiesContainerNew .joinModal .content .btnLoginContainer span {
          color: black;
          text-decoration-line: none;
          margin-right: 4px; }
        .charitiesContainerNew .joinModal .content .btnLoginContainer a {
          color: inherit; }
        .charitiesContainerNew .joinModal .content .btnLoginContainer .btnLogin {
          all: unset;
          cursor: pointer;
          text-decoration-line: underline; }
