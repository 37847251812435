.numberInputContainer {
  width: 190px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #E0E0E0;
  border-radius: 3px;
  background-color: #ffffff; }
  .numberInputContainer a {
    text-decoration: none; }
  .numberInputContainer .reduce {
    border-right: 1px solid #E0E0E0 !important; }
    .numberInputContainer .reduce svg {
      color: #20c1c9 !important; }
  .numberInputContainer .increment {
    border-left: 1px solid #E0E0E0 !important; }
    .numberInputContainer .increment svg {
      color: #20c1c9 !important; }
  .numberInputContainer .result span {
    color: #20c1c9; }
