.footerContainer {
  background-color: #3042d4;
  padding: 40px 100px;
  display: flex; }
  .footerContainer h3 {
    color: #ffffff;
    font-family: 'Poppins' !important;
    font-size: 14px;
    margin-top: 0px; }
  .footerContainer a {
    font-family: 'Poppins' !important;
    color: #ffffff;
    font-size: 14px;
    font-weight: 200;
    display: block;
    text-decoration: none;
    margin-bottom: 10px; }
    .footerContainer a:hover {
      text-decoration: underline;
      color: #ffffff; }
  .footerContainer .leftContainer {
    width: 20%;
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
    .footerContainer .leftContainer .copyright {
      color: #ffffff;
      margin-top: 100px;
      font-size: 12px;
      font-weight: 100; }
  .footerContainer .centreContainer {
    display: flex;
    width: 60%; }
    .footerContainer .centreContainer .yourAccountContainer,
    .footerContainer .centreContainer .aboutContainer {
      margin-right: 10%; }
  .footerContainer .termsContainer {
    width: 20%;
    padding: 0px 24px;
    border-left: 1px solid #ffffff;
    margin-left: 40px; }
    .footerContainer .termsContainer a {
      font-size: 12px; }
    .footerContainer .termsContainer .socialMediaContainer {
      display: flex; }
      .footerContainer .termsContainer .socialMediaContainer a {
        margin-right: 6px; }
  @media screen and (max-width: 1169px) {
    .footerContainer {
      padding: 40px 60px; } }
  @media screen and (max-width: 991px) {
    .footerContainer {
      padding: 40px; } }
  @media screen and (max-width: 767px) {
    .footerContainer {
      padding: 30px;
      flex-direction: column; }
      .footerContainer .leftContainer {
        width: auto;
        flex-direction: row;
        align-items: center; }
        .footerContainer .leftContainer .copyright {
          margin-top: 0px; }
      .footerContainer .centreContainer {
        width: auto;
        flex-wrap: wrap;
        margin-top: 30px; }
        .footerContainer .centreContainer .yourAccountContainer,
        .footerContainer .centreContainer .aboutContainer,
        .footerContainer .centreContainer .searchContainer {
          width: 50%;
          margin: 0px; }
        .footerContainer .centreContainer .searchContainer {
          margin-top: 20px; }
      .footerContainer .termsContainer {
        width: 60%;
        border-left: none;
        border-top: 1px solid #ffffff;
        padding: 30px 0px;
        margin: 30px 0px; } }
