.dashboardContainer {
  position: relative;
  margin-top: 60px;
  display: flex;
  height: calc(100vh - 60px);

  .contentContainer {
    margin-left: 250px;
    max-width: calc(100% - 250px);
  }
}
